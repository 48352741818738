import apiClient from './apiConfig.js';

export const getAllProducts = async (page,size) => {
  try {
    const response = await apiClient.get('/products', {
      params: { 
        page: page,
        size: size }
    }
    );
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: "Failed to fetch products" };
  }
};

export const getProductsByCategory = async (category) => {
  try {
    const response = await apiClient.get('/products/category', {
      params: { category }
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: "Failed to fetch products by category" };
  }
};

export const getProductsByBusiness = async (business) => {
  try {
    const response = await apiClient.get('/products/business', {
      params: { user: business }
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: "Failed to fetch business products" };
  }
};

export const getProduct = async (id) => {
  try {
    const response = await apiClient.get(`/products/${id}`, {
    });
    return response.data;
  } catch (error) {
    // throw error.response?.data || { message: "Failed to fetch product" };
  }
};

export const addProduct = async (productData, images) => {
  try {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const formData = new FormData();

    // Add product details
    formData.append('userId', userData.id);
    formData.append('name', productData.name);
    formData.append('price', productData.price);
    formData.append('description', productData.description);
    formData.append('categoryId', productData.category);
    formData.append('quantity', productData.quantity);
    
    // Optional fields
    if (productData.size) formData.append('size', productData.size);
    if (productData.color) formData.append('color', productData.color);
    if (productData.weight) formData.append('weight', productData.weight);
    if (productData.height) formData.append('height', productData.height);
    if (productData.width) formData.append('width', productData.width);

    // Add images
    if (images.image1) formData.append('image', images.image1);
    if (images.image2) formData.append('image2', images.image2);
    if (images.image3) formData.append('image3', images.image3);
    if (images.image4) formData.append('image4', images.image4);
    if (images.image5) formData.append('image5', images.image5);

    const response = await apiClient.post('/products/upload', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: "Failed to add product" };
  }
};

export const updateProduct = async (id, productData, images) => {
  try {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const formData = new FormData();

    // Add product details
    formData.append('userId', userData.id);
    formData.append('name', productData.name);
    formData.append('price', productData.price);
    formData.append('description', productData.description);
    formData.append('categoryId', productData.category);
    formData.append('quantity', productData.quantity);
    
    // Optional fields
    if (productData.size) formData.append('size', productData.size);
    if (productData.color) formData.append('color', productData.color);
    if (productData.weight) formData.append('weight', productData.weight);
    if (productData.height) formData.append('height', productData.height);
    if (productData.width) formData.append('width', productData.width);

    // Add images
    if (images.image1) formData.append('image', images.image1);
    if (images.image2) formData.append('image2', images.image2);
    if (images.image3) formData.append('image3', images.image3);
    if (images.image4) formData.append('image4', images.image4);
    if (images.image5) formData.append('image5', images.image5);

    const response = await apiClient.post(`/products/${id}/update`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: "Failed to update product" };
  }
};

export const deleteProduct = async (id) => {
  try {
    const response = await apiClient.delete(`/products/${id}`);
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: "Failed to delete product" };
  }
};

export const searchProducts = async (query) => {
  try {
    const response = await apiClient.get('/products/search', {
      params: { query },});
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: "Failed to search products" };
  }
};

export const getCategories = async () => {
  try {
    const response = await apiClient.get('/category/all');
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: "Failed to fetch categories" };
  }
};
