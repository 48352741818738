import apiClient from "./apiConfig";

export const getAllBusinessOrders = async () => {
    const userData = JSON.parse(localStorage.getItem('user'));
    const path = 'orders/business';
    return await apiClient.get(path, {
        params: { id: userData.id }
    });
};

export const getAllOrders = async (status) => {
    const userData = JSON.parse(localStorage.getItem('user'));
    return await apiClient.get('orders', {
        params: { userId: userData.id, status:status.toUpperCase() },
    });
};

export const getByCategory = async (id) => {
    return await apiClient.get('orders/category', {
        params: { category: id }
    });
};

// export const getByBusiness = async (business) => {
//     return await apiClient.get(`orders/business`, {
//         params: { id: parseInt(business) }
//     });
// };

export const deleteOrder = async (id) => {
    return await apiClient.delete(`orders/${id}`, {
    });
};

export const addNewOrder = async (business, address, description, product, quan) => {
    const userData = JSON.parse(localStorage.getItem('user'));
    return await apiClient.post('/orders/new', {
        userId: parseInt(userData.id),
        businessId: parseInt(business),
        address: address?.toString() || " ",
        description: description?.toString() || " ",
        products: product,
        quantity: quan
    }, {
    });
};

export const updateOrder = async (id, { address, description, product, quan, ready, deliveryType }) => {
    const userData = JSON.parse(localStorage.getItem('user'));
    const customer = userData.id;
    const body = { orderId: id, userId: customer };
    
    if (address) body.address = address.toString();
    if (description) body.description = description.toString();
    if (product) body.products = product;
    if (quan) body.quantity = quan;
    if (ready) body.ready = ready.toString();
    if (deliveryType) body.deliveryType = deliveryType.toString();

    return await apiClient.post(`orders/${id}/update`, body, {
    });
};

export const changeOrderStatus = async (id, status, ref) => {
    const userData = JSON.parse(localStorage.getItem('user'));
    const path = `/orders/${id}/change-status`;
    const body = new FormData();
    body.append('userId',  parseInt(userData.id));
    body.append('status', status.toString().toUpperCase());

    if (ref && ref.toString().trim()) {
        body.append('ref', ref.toString().trim());
    }

    return await apiClient.post(path, body,  {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
};

// export const cancelOrder = async (id) => {
//     return await apiClient.post(`orders/${id}/cancel`, {
//     });
// };

// export const confirmOrder = async (id) => {
//     return await apiClient.post(`orders/${id}/confirm`, {
//     });
// };

export const rateOrder = async (id, rating, review) => {
    return await apiClient.post(`orders/${id}/rate`, {
        rating: rating,
        review: review
    });
};

export const fileComplaint = async (id, complaint) => {
    return await apiClient.post(`orders/${id}/complaint`, {
        complaint: complaint
    });
};