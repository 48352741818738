import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import art from '../../assets/images/art.jpg'
import art2 from '../../assets/images/art2.jpg'
import art3 from '../../assets/images/art3.jpg'
import art4 from '../../assets/images/art4.jpg'

const HeroCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
  };

  const slides = [
    {
      image: art,
      title: "Handcrafted Art",
      subtitle: "Discover unique African artworks"
    },
    {
      image: art2,
      title: "Traditional Crafts",
      subtitle: "Shop authentic Nigerian crafts"
    },
    {
      image: art3,
      title: "Modern Designs",
      subtitle: "Contemporary African fashion"
    },
    {
      image: art4,
      title: "Local Artisans",
      subtitle: "Support Nigerian craftsmen"
    }
  ];

  return (
    <div className="hero-carousel">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index} className="carousel-slide">
            <img src={slide.image} alt={slide.title} />
            <div className="slide-content">
              <h2>{slide.title}</h2>
              <p>{slide.subtitle}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default HeroCarousel;