import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';
import './GoogleSignIn.scss';

const GoogleSignIn = ({ onSuccess }) => {
  const handleSuccess = async (credentialResponse) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user/auth/google-login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "token": credentialResponse.credential }),
      });

      const resp = await response.json();
      const data = resp.message;

      if (!response.ok) {
        throw new Error(data || 'Failed to sign in with Google');
      }

      localStorage.clear();
          localStorage.setItem('user', data.token);
          localStorage.setItem('userInfo', JSON.stringify(data.user));
      onSuccess(data);
      
      toast.success('Successfully signed in with Google!');
    } catch (error) {
      toast.error(error.message);
      console.error('Google sign in error:', error);
    }
  };

  const handleError = () => {
    toast.error('Google sign in failed. Please try again.');
  };

  return (
    <div className="google-sign-in">
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        onSuccess={handleSuccess}
        onError={handleError}
        useOneTap
        theme="filled_blue"
        size="large"
        text="signin_with"
        shape="rectangular"
      />
    </div>
  );
};

export default GoogleSignIn;