import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './StoreSetup.scss';
import Util from '../../utils';
import { toast, ToastContainer } from 'react-toastify';
import './Spinner.scss';

const StoreSetupForm = () => {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]); 
  const [banks, setBanks] = useState([]);
  const [bankName, setBankName] = useState('');
  const [businessId, setBizId] = useState('');
  const [formData, setFormData] = useState({
    storeName: '',
    description: '',
    category: '',
    phone: '',
    address: '',
    isRegistered: false,
    registeredNumber: '',
    NIN: '',
    proofOfAddress: null,
    meansOfIdentification: null,
    CACDocument: null,
    otherDocuments: null,
    bank: '',
    accountNumber: '',
    openingHours: ''
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const nextStep = () => setStep((prev) => prev + 1);
  const prevStep = () => setStep((prev) => prev - 1);

  const handleDocumentSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const data = new FormData();
      const user = JSON.parse(localStorage.getItem('userInfo'));
      data.append('userId', user.id);
      data.append('businessName', formData.storeName);
      data.append('description', formData.description);
      data.append('category', formData.category);
      data.append('phone', formData.phone);
      data.append('address', formData.address);
      data.append('registered', formData.isRegistered);
      data.append('regNum', formData.registeredNumber);
      data.append('nin', formData.NIN);
      data.append('poa', formData.proofOfAddress);
      data.append('idCard', formData.meansOfIdentification);
      data.append('cac', formData.CACDocument);
      data.append('otherDocs', formData.otherDocuments);

      const res = await Util.makeRequest('/business/register', { method: 'POST', body: data });

      if (res.httpStatusCode < 300) {
        setBizId(res.message.id);
        toast.success("Documents submitted successfully");
        nextStep();
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to submit documents");
    } finally {
      setLoading(false);
    }
  };

  const handleBankSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const data = new FormData();
      const user = JSON.parse(localStorage.getItem('userInfo'));
      data.append('userId', user.id);
      data.append('businessId',businessId);
      data.append('bankCode', formData.bank);
      data.append('bankName', bankName);
      data.append('accountNum', formData.accountNumber);
      data.append('stage',3)

      const res = await Util.makeRequest('/business/update', { method: 'POST', body: data });

      if (res.httpStatusCode < 300) {
        toast.success("Bank details updated successfully");
        localStorage.clear();
        window.location.href = '/business';
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to update bank details");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (step === 2) {
      handleDocumentSubmit(e);
    } else if (step === 3) {
      handleBankSubmit(e);
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await Util.makeRequest('/category/all');
        setCategories(res.message);
      } catch (error) {
        setError(error.message);
      }
    };

    const getBanks = async () => {
        try {
          const banks = await Util.makeRequest('/payments/banks', 'GET');
          var sorted_banks = banks.message.sort((a,b) => a.name - b.name);
          setBanks(sorted_banks);
        } catch (error) {
        }
    
      }

    fetchCategories();
    getBanks();
  }, []);

  return (
    <div className="store-setup">
      <ToastContainer />
      <h2>Store Setup - Step {step}</h2>
      <form onSubmit={handleSubmit}>
        {step === 1 && (
          <div>
            <h3>Store Info</h3>
            <label>
              Store Name
              <input
                type="text"
                name="storeName"
                value={formData.storeName}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Description
              <textarea
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Category
              <select
                name="category"
                value={formData.category}
                onChange={handleInputChange}
                required
              >
                <option value="">Select a category</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.value}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Phone
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Address
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Is Registered?
              <input
                type="checkbox"
                name="isRegistered"
                checked={formData.isRegistered}
                onChange={handleInputChange}
              />
            </label>
            {formData.isRegistered && (
              <label>
                Registered Number
                <input
                  type="text"
                  name="registeredNumber"
                  value={formData.registeredNumber}
                  onChange={handleInputChange}
                />
              </label>
            )}
            <label>
              NIN
              <input
                type="text"
                name="NIN"
                value={formData.NIN}
                onChange={handleInputChange}
                required
              />
            </label>
          </div>
        )}

        {step === 2 && (
          <div>
            <h3>Documents</h3>
            <label>
              Proof of Address
              <input
                type="file"
                name="proofOfAddress"
                onChange={handleFileChange}
                accept=".pdf,.png,.jpg"
                maxLength={5 * 1024 * 1024}
                // required
              />
            </label>
            <label>
              Means of Identification
              <input
                type="file"
                name="meansOfIdentification"
                onChange={handleFileChange}
                accept=".pdf,.png,.jpg"
                maxLength={5 * 1024 * 1024}
                // required
              />
            </label>
            {formData.isRegistered && (
              <label>
                CAC Document
                <input
                  type="file"
                  name="CACDocument"
                  onChange={handleFileChange}
                  accept=".pdf,.png,.jpg"
                maxLength={5 * 1024 * 1024}
                />
              </label>
            )}
            <label>
              Other Documents
              <input
                type="file"
                name="otherDocuments"
                onChange={handleFileChange}
                accept=".pdf,.png,.jpg"
                maxLength={5 * 1024 * 1024}
                multiple
              />
            </label>
          </div>
        )}

        {step === 3 && (
          <div>
            <h3>Bank Details</h3>
            <label>
              Bank
              <select
                name="bank"
                value={formData.bank}
                onChange={(e) => {
                  const selectedBank = e.target.options[e.target.selectedIndex];
                  handleInputChange(e);
                  setBankName(selectedBank.dataset.code);
                  }}
                required
              >
                <option value="">Select a bank</option>
                {
                banks.map((bank) => (
                  <option key={bank.code} value={bank.code} data-code={bank.name}>
                    {bank.name}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Account Number
              <input
                type="text"
                name="accountNumber"
                value={formData.accountNumber}
                onChange={handleInputChange}
                required
              />
            </label>
          </div>
        )}

        {/* {step === 4 && (
          <div>
            <h3>Opening Hours</h3>
            <label>
              Enter Your Opening Hours
              <input
                type="text"
                name="openingHours"
                value={formData.openingHours}
                onChange={handleInputChange}
                required
              />
            </label>
          </div>
        )} */}

        <div className="form-navigation">
          {step > 1 && (
            <button 
              type="button" 
              onClick={prevStep}
              disabled={loading}
            >
              Previous
            </button>
          )}
          {step < 2 && (
            <button 
              type="button" 
              onClick={nextStep}
              disabled={loading}
            >
              Next
            </button>
          )}
          {step > 1 && (
            <button 
              type="submit"
              disabled={loading}
              className={loading ? 'loading' : ''}
            >
              {loading ? (
                <span className="spinner"></span>
              ) : (
                'Submit'
              )}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

StoreSetupForm.propTypes = {};

export default StoreSetupForm;
