import Util from '../../utils';

export const USER_LOGIN_LOADING = 'USER_LOGIN_STARTED';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_ERROR = 'USER_LOGIN_FAILED';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const CLEAR_LOGIN_ERRORS = 'CLEAR_LOGIN_ERRORS';

export const userLoginLoading = loadingStatus => ({
  type: USER_LOGIN_LOADING,
  payload: loadingStatus
});

export const userLoginError = (error, message) => ({
  type: USER_LOGIN_ERROR,
  payload: error ? [...error] : [message]
});

export const clearLoginErrors = () => ({ type: CLEAR_LOGIN_ERRORS });

export const logOutUser = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('cart');
  return {
    type: USER_LOGGED_OUT,
    payload: { isAuthenticated: false, userRole: 'guest' }
  };
};

export const loginUser = userInfo => async dispatch => {
  try {
    dispatch(userLoginLoading(true));

    const options = {
      method: 'POST',
      body: userInfo
    };

    const { message } = await Util.makeRequest('/user/auth/loginPassword', options);
    const data = message

    const userDetails = {
      token: data.token,
      role: data.role
    };
    localStorage.clear();
    localStorage.setItem('user', message.token);
    localStorage.setItem('userInfo', JSON.stringify(message.user));
    if (data.role === 'Attendant') localStorage.setItem('cart', '[]');
    if (message.user.businessId != null || message.user.businessId > 0 ) {
      
      const data1 = await Util.makeRequest('/business/' + message.user.businessId);
      localStorage.setItem('biz',data1.message)
      if (data1.message.stage <3) {
        window.location.href ="/business/setup"
      } else {
        
        
        dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: { isAuthenticated: true, userRole: message.user.role }
          // payload: { isAuthenticated: true, userRole: "Admin" }
        });
      }
    } else {
      window.location.href ="/business/setup"

    }
  } catch (err) {
    const { error, message } = err;
    dispatch(userLoginError(error, message));
  }
};


export const googleLogin = data => async dispatch => {
  try {
    dispatch(userLoginLoading(true));

    if (data.user.role === 'Attendant') localStorage.setItem('cart', '[]');
    if (data.user.businessId != null || data.user.businessId > 0 ) {
      
      const data1 = await Util.makeRequest('/business/' + data.user.businessId);
      localStorage.setItem('biz',data1.message)
      if (data1.message.stage <3) {
        window.location.href ="/business/setup"
      } else {
        
        
        dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: { isAuthenticated: true, userRole: data.user.role }
          // payload: { isAuthenticated: true, userRole: "Admin" }
        });
      }
    } else {
      window.location.href ="/business/setup"

    }
  } catch (err) {
    const { error, message } = err;
    console.log('Google login error:', err);
    dispatch(userLoginError(error, message));
  }
};

export const sendOtp = userInfo => async dispatch => {
  try {
    dispatch(userLoginLoading(true));

    const options = {
      method: 'POST',
      body: userInfo
    };
    
    const data = await Util.makeRequest('/user/auth/send-otp', options);

    // const userDetails = {
    //   token: data.token,
    //   role: data.role
    // };

    // localStorage.setItem('user', data.token);
    // if (data.role === 'Attendant') localStorage.setItem('cart', '[]');
    localStorage.setItem('userMail', userInfo.email);

    dispatch({
      type: USER_LOGIN_SUCCESS,
      // payload: { isAuthenticated: true, userRole: userDetails.role }
      payload: {otpSent:true, user:userInfo.email}
    });
  } catch (err) {
    const { error, message } = err;
    dispatch(userLoginError(error, message));
  }
};

export const register = userInfo => async dispatch => {
  try {
    dispatch(userLoginLoading(true));

    const options = {
      method: 'POST',
      body: userInfo
    };
    
    const data = await Util.makeRequest('/user/auth/register', options);

    // const userDetails = {
    //   token: data.token,
    //   role: data.role
    // };

    // localStorage.setItem('user', data.token);
    // if (data.role === 'Attendant') localStorage.setItem('cart', '[]');
    localStorage.setItem('userMail', userInfo.email);
    sendOtp({"email":userInfo.email})
    dispatch({
      type: USER_LOGIN_SUCCESS,
      // payload: { isAuthenticated: true, userRole: userDetails.role }
      payload: {otpSent:true, user:userInfo.email}
    });
  } catch (err) {
    const { error, message } = err;
    dispatch(userLoginError(error, message));
  }
};

export const resendOtp = userInfo => async dispatch => {
  try {
    dispatch(userLoginLoading(true));

    const options = {
      method: 'POST',
      body: userInfo
    };

    const data  = await Util.makeRequest('/user/auth/send-otp', options);

    dispatch({
      type: USER_LOGIN_SUCCESS,
      // payload: { isAuthenticated: true, userRole: userDetails.role }
      payload: {otpResent:true, user:userInfo.email}
    });
  } catch (err) {
    const { error, message } = err;
    dispatch(userLoginError(error, message));
  }
};

export const verifyOtp = userInfo => async dispatch => {
  try {
    dispatch(userLoginLoading(true));

    const options = {
      method: 'POST',
      body: userInfo
    };

    const { data } = await Util.makeRequest('/user/auth/verify-email', options);

    dispatch({
      type: USER_LOGIN_SUCCESS,
      // payload: { isAuthenticated: true, userRole: userDetails.role }
      payload: {otpVerified:true,user:userInfo.email}
    });
  } catch (err) {
    const { error, message } = err;
    dispatch(userLoginError(error, message));
  }
};

export const setPassword = userInfo => async dispatch => {
  try {
    dispatch(userLoginLoading(true));

    const options = {
      method: 'POST',
      body: userInfo
    };

    const resp = await Util.makeRequest('/user/auth/set-password', options);

    dispatch({
      type: USER_LOGIN_SUCCESS,
      // payload: { isAuthenticated: true, userRole: userDetails.role }
      payload: {passwordSet:true,user:userInfo.email}
    });
  } catch (err) {
    const { error, message } = err;
    dispatch(userLoginError(error, message));
  }
};
