import React, { useState, useEffect } from 'react';
import Logo from '../assets/images/arena-logo-white.svg';
import filter from '../assets/images/filter.svg';
import img1 from '../assets/images/craft.jpg';
import grid from '../assets/images/grid.svg';
import viewList from '../assets/images/view-list.svg';
import PageHeader from '../components/sections/PageHeader';
import Button from '../components/elements/Button';
import { getAllProducts, getCategories } from '../network/product_api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import '../assets/scss/components/_search.scss';
import { formatPrice } from '../network/currency_api';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from 'react-toastify';
import { addNewOrder } from '../network/order_api';
import 'react-toastify/dist/ReactToastify.css';  // Add this import

const Shop = () => {
    const [viewMode, setViewMode] = useState('grid');
    const [showFilters, setShowFilters] = useState(false);
    
    const [loadingCart, setLoadingCart] = useState({});
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(40);
    const [totalPages, setTotalPages] = useState(0);
    const [totalNumber, setTotalNumber] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    // const [pageLoading, setPageLoading] = useState(true);
    
    useEffect(() => {
      const fetchProducts = async () => {
        try {
          setLoading(true);
          const response = await getAllProducts(page,size);
          setProducts(response["content"]); // Get first 8 products
          setTotalPages(response["totalPages"]);
          setTotalNumber(response["totalElements"]);
          setLoading(false);
        } catch (err) {
          setError('Failed to fetch products');
          setLoading(false);
        }
      };
      
      // Get list of categories
      const fetchCategories = async () => {
        try {
          const data = await getCategories();
          setCategories(data["message"]);
        } catch (err) {
        }
      };
      fetchCategories();
    
      fetchProducts();
    }, [page, size]);

    useEffect(() => {
      const results = products.filter(product =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredProducts(results);
    }, [searchTerm, products]);


    const handleAddToCart = async (e, product) => {
        e.preventDefault(); // Prevent navigation
        e.stopPropagation(); // Prevent event bubbling

        
        const token = localStorage.getItem('token');
        if (!token) {
          return toast.error('Please login to add items to cart');
        }
        // try {
            setLoadingCart(prev => ({ ...prev, [product.id]: true }));
            // Check if user is logged in
            const order_description = `${product.name}`;
            addNewOrder(product.user.id, "", order_description, product.id, 1).then(() => {
              setLoadingCart(prev => ({ ...prev, [product.id]: false }));
              return toast.success('Added to cart!');
            }).catch((error) => {
              setLoadingCart(prev => ({ ...prev, [product.id]: false }));
            return toast.error(error.message || 'Failed to add to cart');
        } ).finally(() => {
            // setLoadingCart(prev => ({ ...prev, [product.id]: false }));
        });
    };

    const renderProducts = () => {
        if (loading) return <div>Loading products...</div>;
        return (
            <div className={`shop products-${viewMode}`}>
                <div className="shop products-list">
                    {(searchTerm ? filteredProducts : products).map((prod) => {
                        const discount = (prod.dicountpercent && prod.dicountpercent > 0) 
                            ? prod.price * (prod.dicountpercent / 100) 
                            : 0;
                        
                        return (
                            <a key={prod.id} 
                               href={`/product/${prod.id}/?prod=${prod.name}`} 
                               style={{textDecoration:"none"}}
                               onClick={() => {
                                   localStorage.setItem('selectedProduct', JSON.stringify(prod));
                               }}>
                                <div className="product-item">
                                    <img src={prod.imageUrl} alt={prod.name} />
                                    {prod.dicountpercent != null && prod.dicountpercent > 0 && (
                                        <div className='discount-label'>{prod.dicountpercent}% OFF</div>
                                    )}
                                    <div className='item-bottom'>
                                        <p className='item-name'>
                                            {prod.name && (prod.name.length > 20 
                                                ? prod.name.substring(0, 20) + '...' 
                                                : prod.name)}
                                        </p>
                                        <p className='item-price'>
                                            {prod.dicountpercent != null && prod.dicountpercent > 0 ? (
                                                <>
                                                    <span>{formatPrice(prod.price - discount)}</span>
                                                    <span className='item-oldPrice'>
                                                        <s>{formatPrice(prod.price)}</s>
                                                    </span>
                                                </>
                                            ) : (
                                                <span>{formatPrice(prod.price)}</span>
                                            )}
                                        </p>
                                    <button 
                                        className={`add-to-cart-button ${loadingCart[prod.id] ? 'loading' : ''}`}
                                        onClick={(e) => handleAddToCart(e, prod)}
                                        disabled={loadingCart[prod.id]}
                                    >
                                        {loadingCart[prod.id] ? (
                                            <span className="loader"></span>
                                        ) : (
                                            <>
                                                <FontAwesomeIcon icon={faShoppingCart} className="cart-icon" />
                                                <span className="button-text">Add to Cart</span>
                                            </>
                                        )}
                                    </button>
                                    </div>
                                </div>
                            </a>
                        );
                    })}
                </div>
            </div>
        );
    };

    const filterProductsByCategory = (products) => {
      if (selectedCategory === 'all') return products;
      return products.filter(product => product.category.id === selectedCategory);
    };

    return (
      <React.Fragment>
      <PageHeader page="Shop"></PageHeader>
      <ToastContainer/>

      {loading && (
            <div className="page-loading-overlay">
                <div className="loader-container">
                    <div className="loader"></div>
                    <p>Loading products...</p>
                </div>
            </div>
        )}
      <div className='shop-band'>
      <section className='sb-left'>
      <div className='filter'>
        <i className='f-icon' onClick={() => setShowFilters(!showFilters)}>
        <img src={filter}/>
        </i>
        {/* <i className='f-icon' onClick={() => setViewMode('grid')}>
        <img src={grid} style={{opacity: viewMode === 'grid' ? 1 : 0.5}}/>
        </i>
        <i className='f-icon' onClick={() => setViewMode('list')}>
        <img src={viewList} style={{opacity: viewMode === 'list' ? 1 : 0.5}}/>
        </i> */}
      </div>
      <p className='page-numbers'>Showing {page * size + 1}-{Math.min((page + 1) * size, totalNumber)} of {totalNumber}</p>
      </section>
      <section className='sb-right'>
      </section>
      </div>

      <div className="search-container">
      <div className="search-wrapper">
      <FontAwesomeIcon icon={faSearch} className="search-icon" />
      <input
        type="text"
        placeholder="Search products..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-input"
      />
      </div>
      </div>

      <div className="shop-container">
      <div className="shop-layout">
        <aside className={`categories-sidebar ${showFilters ? 'show' : 'hide'}`}>
        <h3>Categories</h3>
        <ul className="categories-list">
          <li 
          className={selectedCategory === null ? 'active' : ''}
          onClick={() => setSelectedCategory(null)}
          >
          All Products
          </li>
          {categories.map(category => (
          <li
            key={category.id}
            className={selectedCategory === category.id ? 'active' : ''}
            onClick={() => {
            setSelectedCategory(category.id);
            setSearchTerm(' ');
            const filtered = products.filter(product => product.category.id === category.id);
            setFilteredProducts(filtered);
            }}
          >
            {category.value}
            <span className="category-count">
            ({products.filter(p => p.category.id === category.id).length})
            </span>
          </li>
          ))}
        </ul>
        </aside>
        
        <div className="products-section">
        {renderProducts()}
        </div>
      </div>
      </div>
      
      <div style={{textAlign:"center", margin:"15px 0px"}}>
      <Button 
      className="button-primary"
      onClick={() => setPage(page - 1)}
      disabled={page === 0}
      style={{marginRight: '10px'}}>
      {/* <FontAwesomeIcon icon="fa-solid fa-chevron-left" /> */}
      </Button>
      <span style={{margin: '0 10px'}}>
      {page + 1} of {totalPages}
      </span>
      <Button 
      className="button-primary"
      onClick={() => setPage(page + 1)}
      disabled={page >= totalPages - 1}>
      {/* <FontAwesomeIcon icon="fa-solid fa-chevron-right" /> */}
      </Button>
      </div>
      </React.Fragment>
    );
}

export default Shop;