// import React from 'react';
// import ReactDOM from 'react-dom';
// import { Router } from "react-router-dom";
// import { createBrowserHistory } from "history";

// import App from './App';
// import * as serviceWorker from './serviceWorker';

// //import './App.css';
// import './assets/scss/style.scss';

// const history = createBrowserHistory();

// ReactDOM.render(
//   <Router history={history}>
//     <App />
//   </Router>,
//   document.getElementById('root')
// );

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();


import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

import './assets/scss/style.scss';
import { toast, ToastContainer } from 'react-toastify';
import { HelmetProvider } from 'react-helmet-async';

// Add global error handlers
window.addEventListener('unhandledrejection', (event) => {
  console.error('Unhandled promise rejection:', event.reason);
  toast.error('An unexpected error occurred. Please try again.');
});

// Prevent default error overlay in development
if (process.env.NODE_ENV === 'production') {
  console.error = (...args) => {
    console.log(...args);
  };
}

ReactDOM.render(
  <React.StrictMode>
    {/* <BrowserRouter> */}
    <HelmetProvider>
      <App />
      <ToastContainer />
    {/* </BrowserRouter> */}
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
