import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import "../../assets/scss/core/elements/_products.scss";
import Button from '../elements/Button';
import { formatPrice } from '../../network/currency_api';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class Products extends React.Component {

  render() {

    const {
      products = [],
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'news section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'news-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Our Products',
      // paragraph: 'To find a diverse array of handcrafted products, vintage items, and craft supplies.'
    };
    // const calculateDiscountPrice = (price, discountPercent) => {
      //   if (!discountPercent) return price;
    //   return price - discount;
    // };


    return (
      <section
      {...props}
      className={outerClasses}
      >
      <div className="container">
        <div className={innerClasses}>
        <SectionHeader data={sectionHeader} className="center-content reveal-from-bottom" />
        <div className="products-list">
      {products.map((prod) => {
        const discount = prod.price * (prod.discountPercent / 100);
        return (<a href={`/product/${prod.id}/?prod=${prod.name}`} style={{textDecoration:"none"}}
        onClick={() => {
          // Store the product data in localStorage before navigation
          localStorage.setItem('selectedProduct', JSON.stringify(prod));
        }} >
      <div className="product-item" key={prod.id}>
        <img src={prod.imageUrl} alt={prod.name} />
        { prod.dicountpercent != null || prod.dicountpercent > 0 ?
        <div className='discount-label' >{prod.dicountpercent}%</div>
        :
        // <div className='new-label' >New</div>
        <></>
        }
        <div className='item-bottom'>
                                                <p className='item-name'>
                                                    {prod.name && (prod.name.length > 20 
                                                        ? prod.name.substring(0, 20) + '...' 
                                                        : prod.name)}
                                                </p>
                                                <p className='item-price'>
                                                    {prod.dicountpercent != null && prod.dicountpercent > 0 ? (
                                                        <>
                                                            <span>{formatPrice(prod.price - discount)}</span>
                                                            <span className='item-oldPrice'>
                                                                <s>{formatPrice(prod.price)}</s>
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <span>{formatPrice(prod.price)}</span>
                                                    )}
                                                </p></div>
      </div>
      </a> );
      }
      )}
    </div>
        <div style={{textAlign:"center", margin:"15px 0px"}}>

    <Button tag="a" className="button-primary" href="/shop">Show More</Button>
        </div>
        </div>
      </div>
      </section>
    );
  }
}

Products.propTypes = propTypes;
Products.defaultProps = defaultProps;

export default Products;