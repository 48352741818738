import axios from 'axios';

const BASE_CURRENCY = 'NGN';
let exchangeRates = 0.0;
window.exchangeRates = exchangeRates;
let currentCurrency = localStorage.getItem('selectedCurrency') || 'USD';

export const getExchangeRates = async () => {
    try {
        currentCurrency = localStorage.getItem('selectedCurrency') || 'USD';
        const response = await axios.get(
            `https://hexarate.paikama.co/api/rates/latest/${BASE_CURRENCY}?target=${currentCurrency}`
        );
        exchangeRates = response.data.data.mid;
        // window.exchangeRates = response.data.data.mid;
        return exchangeRates;
    } catch (error) {
        console.error('Failed to fetch exchange rates:', error);
        return null;
    }
}

export const convertPrice =(price) => {
    console.log('price:', exchangeRates);
    if (!exchangeRates) return price;
    return (price * exchangeRates).toFixed(2);
}

export const formatPrice = (price, currency = currentCurrency) => {
    // console.log('price:', exchangeRates);
    // convertPrice(price).then((converted) => {
    //     if (!exchangeRates) return new Intl.NumberFormat('en-US', {style: 'currency',currency: BASE_CURRENCY}).format(converted);
    //     return new Intl.NumberFormat('en-US', {
    //         style: 'currency',
    //         currency: currency
    //     }).format(converted);
    // });
    const converted = convertPrice(price);
    if (!exchangeRates) return new Intl.NumberFormat('en-US', {style: 'currency',currency: BASE_CURRENCY}).format(converted);
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency
    }).format(converted);
}

// Remove default export and use named exports instead