
import apiClient from './apiConfig';
export const getLogisticById = async (id) => {
    const path = `logistics/${id}`;
    const response = await apiClient.get(path);
    
    return response;
};

export const addNewLogistic = async (orderId, frmAddress, toAddress, contactPhone, contactName, courier, rateKey, price, weight, height, width) => {
    const path = 'logistics/register';
    const user = JSON.parse(localStorage.getItem('user'));
    
    const body = {
        orderId,
        userId: user.id,
        frmAddress,
        toAddress,
        contactPhone,
        contactName,
        courier,
        redisKey: rateKey,
        price,
        status: "pending",
        driverPhone: null,
        driverName: null,
        weight,
        height,
        width
    };

    const response = await apiClient.post(path, body);
    return response;
};