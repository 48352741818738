import React, { useEffect, useState } from 'react';
import '../../assets/scss/components/_currency-selector.scss';
import { getExchangeRates } from '../../network/currency_api';

const CURRENCIES = [
  { code: 'NGN', name: 'Nigerian Naira' },
  { code: 'USD', name: 'US Dollar' },
  { code: 'EUR', name: 'Euro' },
  { code: 'GBP', name: 'British Pound' },
  { code: 'AUD', name: 'Australian Dollar' },
  { code: 'CAD', name: 'Canadian Dollar' },
  { code: 'CHF', name: 'Swiss Franc' },
  { code: 'CNY', name: 'Chinese Yuan' },
  { code: 'JPY', name: 'Japanese Yen' },
  { code: 'ZAR', name: 'South African Rand' }
];

const CurrencySelector = () => {
  const [selectedCurrency, setSelectedCurrency] = useState(
    localStorage.getItem('selectedCurrency') || "USD"
  );

  useEffect(() => {
    const loadCurrencies = async () => {
      try {
        await getExchangeRates();
      } catch (error) {
        console.error('Failed to load currencies:', error);
      }
    };
    
    loadCurrencies();
  }, [selectedCurrency]);

  const handleCurrencyChange = (event) => {
    const currency = event.target.value;
    setSelectedCurrency(currency);
    localStorage.setItem('selectedCurrency', currency);
    window.location.reload();
    // window.dispatchEvent(new Event('currencyChange'));
  };

  return (
    <div className="currency-selector-container">
      <select 
        value={selectedCurrency}
        onChange={handleCurrencyChange}
        className="currency-selector"
      >
        {CURRENCIES.map(({ code, name }) => (
          <option key={code} value={code}>
            {code} 
            {/* - {name} */}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CurrencySelector;