
// Cache for API responses to minimize calls
let chaptersCache = null;
let categoriesCache = new Map();

export const getHSCodeMapping = async (productCategory) => {
    // const API_BASE_URL = 'https://delivery-staging.apiideraos.com/api/v2/token';
    const API_BASE_URL = 'https://delivery.apiideraos.com/api/v2/token';
    const API_KEY = 'ssk_e91efc835b659fc0e05fb1d34ceb2b1bcc3ef0b15fd639cb5cd0c86abba3d4aa'; // Replace with your actual key
    try {
        // Fetch chapters if not cached
        if (!chaptersCache) {
            const chaptersResponse = await fetch(`${API_BASE_URL}/hscode/chapters`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${API_KEY}`,
                },
            });
            
            if (!chaptersResponse.ok) {
                throw new Error('Failed to fetch chapters');
            }
            
            const chaptersData = await chaptersResponse.json();
            chaptersCache = chaptersData.data;
        }

        // Find the most similar chapter based on product category
        const matchedChapter = chaptersCache.reduce((closest, chapter) => {
            const similarity = (chapter || '').toLowerCase()
                .split(/\W+/)
                .filter(word => 
                    productCategory.toLowerCase().includes(word) ||
                    word.length > 3 && productCategory.toLowerCase()
                        .split(/\W+/)
                        .some(prodWord => 
                            prodWord.includes(word) || 
                            word.includes(prodWord)
                        )
                ).length;
            
            return similarity > (closest.similarity || 0) 
                ? { id: chapter, similarity } 
                : closest;
        }, {}).id || chaptersCache[0].id;
        
        // Check if categories for this chapter are cached
        if (!categoriesCache.has(matchedChapter)) {
            const categoriesResponse = await fetch(
                `${API_BASE_URL}/hscode/categories?chapter_id=${encodeURIComponent(matchedChapter)}`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${API_KEY}`,
                    },
                }
            );
            
            if (!categoriesResponse.ok) {
                throw new Error('Failed to fetch categories');
            }
            
            const categoriesData = await categoriesResponse.json();
            categoriesCache.set(matchedChapter, categoriesData.data);
        }
            const categories = categoriesCache.get(matchedChapter);
            // Find the most relevant category based on product category
            const selectedCategory = categories.reduce((closest, category) => {
                const similarity = (category.description || '').toLowerCase()
                    .split(/\W+/)
                    .filter(word => 
                        productCategory.toLowerCase().includes(word) ||
                        word.length > 3 && productCategory.toLowerCase()
                            .split(/\W+/)
                            .some(prodWord => 
                                prodWord.includes(word) || 
                                word.includes(prodWord)
                            )
                    ).length;
                
                return similarity > (closest.similarity || 0) 
                    ? { ...category, similarity } 
                    : closest;
            }, {})|| categories[0];
        
        return {
            chapter: matchedChapter,
            category: selectedCategory.category,
            subcategory: selectedCategory.subcategory,
            category_code: selectedCategory.category_code,
            hscode: selectedCategory.hscode,
            description: selectedCategory.description
        };
        
        // {
        //     "chapter": 31,
        //     "category_code": "3102",
        //     "category": "Chemicals & Allied Industries",
        //     "subcategory": "Fertilizers",
        //     "hscode": "310210",
        //     "description": "Urea, aqueous or solid"
        // },

    } catch (error) {
        console.error('Error in HSCode mapping:', error);
        // Return default mapping if API fails
        return {
            chapter: 'Other Made-Up Textile Articles; Sets',
            category: 'Textiles',
            hscode: '630790',
            description: 'Other made-up articles'
        };
    }
};