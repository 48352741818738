import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import { Link } from 'react-router-dom';
import SectionHeader from './partials/SectionHeader';
import Input from '../elements/Input';
import Button from '../elements/Button';
import Checkbox from '../elements/Checkbox';
import { login } from '../../network/api';
import { GoogleLogin } from '@react-oauth/google';
import GoogleSignIn from './GoogleSignin';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class LoginForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      // password: '',
      deviceId: '',
      rememberMe: false
    }
  }

  componentDidMount() {
    const token = localStorage.getItem('token');
    if (token) {
      window.location.href = '/shop';
    }
  }
  handleLogin = async () => {
    this.setState({ loading: true, error: null });
    // await getDeviceId();
    const body = {
      'email': this.state.email,
      'deviceId': this.state.deviceId,
      'rememberMe': this.state.rememberMe
    }
    login( body )
      .then(response => {
        if (response.httpStatusCode === 200) {
          // Handle successful login, e.g. redirect or store token
          const resp = response.message;
          localStorage.clear();
          localStorage.setItem('token', resp.token);
          localStorage.setItem('user', JSON.stringify(resp.user));
          // Redirect to dashboard
            const savedPage = localStorage.getItem('redirectAfterLogin') || '/shop';
            localStorage.removeItem('redirectAfterLogin'); // Clear the saved redirect
            window.location.href = savedPage;
        } else {
          // Handle login failure 
          this.setState({ error: response.message, loading: false });
        }
      })
      .catch(error => {
        this.setState({ error: 'An error occurred. Please try again.', loading: false });
      });
  }

  handleGoogleSuccess = (userData) => {
    // Redirect to home or dashboard
    const savedPage = localStorage.getItem('redirectAfterLogin') || '/shop';
            localStorage.removeItem('redirectAfterLogin'); // Clear the saved redirect
            window.location.href = savedPage;
  };

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'signin section login-bg',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'signin-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
      title: 'Welcome back',
    };

    

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container" style={{ display: "flex",
    justifyContent: "center" }}>
          <div className={innerClasses} style={{ width:"400px", maxWidth:"80%", background:"rgba(255,255,255,1)", padding:"25px 20px", borderRadius:"20px"}}>
            <SectionHeader tag="h1" data={sectionHeader} className="center-content" />
            <div className="tiles-wrap">
              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <form className="signin-form"> 
                  <fieldset disabled={this.state.loading}>
                      <div className="mb-12">
                        <Input
                          type="email"
                          label="Email"
                          placeholder="Email"
                          onChange={(e) => this.setState({ email: e.target.value })}
                          labelHidden
                          required />
                      </div>
                      {/* <div className="mb-12">
                        <Input
                          type="password"
                          label="Password"
                          placeholder="Password"
                          labelHidden
                          required
                          onChange={(e) => this.setState({ password: e.target.value })}
                        />
                      </div> */}
                      <div className="mt-24 mb-32">
                        <Button type="button" color="primary" wide onClick={this.handleLogin} disabled={this.state.loading}>
                        {this.state.loading ? 'Proceeding...' : 'Sign in'}
                          </Button>
                      </div>
                      <div className="signin-footer mb-32">
                        {/* <Checkbox onChange={(e) => this.setState({ rememberMe: e.target.checked })}>Remember me</Checkbox> */}
                      {/* </div>} */}
                      </div>
                    </fieldset>
                  </form>
                  <div className="divider" style={{textAlign:"center"}}>
        <span>OR</span>
      </div>
      
      <GoogleSignIn onSuccess={this.handleGoogleSuccess} />
                  <div className="signin-bottom has-top-divider">
                    <div className="pt-32 text-xs center-content text-color-low">
                      Don't you have an account? <Link to="/register/" className="func-link">Sign up</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

LoginForm.propTypes = propTypes;
LoginForm.defaultProps = defaultProps;

export default LoginForm;