import React, { useEffect, useState } from 'react';
import Logo from '../assets/images/arena-logo-white.svg';
import filter from '../assets/images/filter.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import img1 from '../assets/images/craft.jpg';
import grid from '../assets/images/grid.svg';
import viewList from '../assets/images/view-list.svg';
import PageHeader from '../components/sections/PageHeader';
import Button from '../components/elements/Button';
import Products from '../components/sections/Products';
import { addNewOrder } from '../network/order_api';
import { or } from 'ajv/dist/compile/codegen';
import { formatPrice } from '../network/currency_api';
import { getProduct } from '../network/product_api';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Product = () => {

    // const products = ;
      const [products, setProducts] = useState([]);
      const [similarProducts, setSimilarProducts] = useState([]);

      //   get the product from local storage
      // const product = JSON.parse(localStorage.getItem('selectedProduct'));
      const [product, setProduct] = useState(JSON.parse(localStorage.getItem('selectedProduct')));
      const pid = useParams().id;
    const [quantity, setQuantity] = React.useState(1);
    // const [order_description, setDescription] = React.useState("");
    const [o_size, setSize] = React.useState("");
    const [o_color, setColor] = React.useState("");
    const [o_variant, setVariant] = React.useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [mainImage, setMainImage] = React.useState();
    const [allImages, setAllImages] = React.useState([]);
    const [pageLoading, setPageLoading] = useState(true);

    
    useEffect(() => {
        const fetchProductData = async () => {
            try {
                setPageLoading(true);
                if (!pid) return;
    
                const prod = await getProduct(pid);
                if (!prod) throw new Error('Product not found');
    
                setProduct(prod.product);
                setMainImage(prod.product.imageUrl);
                setProducts(prod.vendorProducts || []);
                setSimilarProducts(prod.similarProducts || []);
    
                if (prod.product) {
                    setAllImages([
                        prod.product.imageUrl,
                        ...[
                            prod.product.image2, 
                            prod.product.image3, 
                            prod.product.image4, 
                            prod.product.image5
                        ].filter(Boolean)
                    ]);
                }
                setPageLoading(false);
            } catch (error) {
                console.error('Error fetching product:', error);
                setError(error.message || 'Failed to fetch product');
                setPageLoading(false);
                toast.error('Failed to load product details');
            } finally {
            }
        };
    
        fetchProductData();
    }, [pid]);
    
    const increaseQuantity = () => {
        setQuantity(prev => prev + 1);
    };

    const decreaseQuantity = () => {
        setQuantity(prev => prev > 1 ? prev - 1 : 1);
    };


    const handleImageClick = (imageUrl) => {
        setMainImage(imageUrl);
    };

    const [showSlideshow, setShowSlideshow] = React.useState(false);
    const [currentSlideIndex, setCurrentSlideIndex] = React.useState(0);


    const nextSlide = () => {
        setCurrentSlideIndex((prev) => (prev + 1) % allImages.length);
    };

    const prevSlide = () => {
        setCurrentSlideIndex((prev) => (prev - 1 + allImages.length) % allImages.length);
    };
    

    const addToCart = async () => {
        setLoading(true);
        setError(null);
        try {
            // Check if user is logged in by looking for token in localStorage
            const token = localStorage.getItem('token');
            if (!token) {
            toast.error('Please login to add items to cart');
            setLoading(false);
            return;
            }

            const order_description = `${product.name} : ${o_size} ${o_color} ${o_variant}`;
            const response = await addNewOrder(product.user.id, "", order_description, product.id, quantity);
            
            toast.success('Product added to cart successfully!');
        } catch (error) {
            setError(error.message || 'Failed to add product to cart');
            toast.error(error.message || 'Failed to add product to cart');
        } finally {
            setLoading(false);
        }
    };

    const renderProductImages = () => (
        <section className='image-section'>
          <div className='images-list'>
            <ul>
              {allImages.map((image, index) => (
                <li 
                  key={index}
                  className={mainImage === image ? 'active' : ''}
                  onClick={() => handleImageClick(image)}
                >
                  <img src={image} alt={`Product view ${index + 1}`} />
                </li>
              ))}
            </ul>
          </div>
          
          <div className="main-image-container">
            <img 
              src={mainImage} 
              className='main-prod-image' 
              onClick={() => {
                setCurrentSlideIndex(allImages.indexOf(mainImage));
                setShowSlideshow(true);
              }}
              alt={product?.name}
            />
          </div>
        </section>
      );
    
      const renderVariants = () => (
        <>
          {product?.size && (
            <div className="variants-section">
              <p className="variant-title">Size</p>
              <div className="variant-options">
                {product.size.split(',').map((size, index) => (
                  <button
                    key={index}
                    className={`variant-option ${o_size === size.trim() ? 'selected' : ''}`}
                    onClick={() => setSize(size.trim())}
                  >
                    {size.trim()}
                  </button>
                ))}
              </div>
            </div>
          )}
    
          {product?.color && (
            <div className="variants-section">
              <p className="variant-title">Color</p>
              <div className="variant-options">
                {product.color.split(',').map((color, index) => (
                  <button
                    key={index}
                    className={`variant-option ${o_color === color.trim() ? 'selected' : ''}`}
                    onClick={() => setColor(color.trim())}
                    style={{
                      backgroundColor: color.trim().toLowerCase(),
                      color: ['white', 'yellow', 'beige'].includes(color.trim().toLowerCase()) ? 'black' : 'white'
                    }}
                  >
                    {color.trim()}
                  </button>
                ))}
              </div>
            </div>
          )}
        </>
      );
    
      const renderQuantityControls = () => (
        <div className="quantity-controls">
          <button onClick={decreaseQuantity} disabled={quantity <= 1}>-</button>
          <span>{quantity}</span>
          <button onClick={increaseQuantity}>+</button>
        </div>
      );

      const renderRelatedProducts = (title, productsList) => (
        <div className="related-products-section">
          <h2 className="section-title">{title}</h2>
          <div className="products-grid">
            {productsList.slice(0, 4).map(prod => (
              <div key={prod.id} className="product-card" onClick={() => {
                localStorage.setItem('selectedProduct', JSON.stringify(prod));
                window.location.href = `/product/${prod.id}`;
              }}>
                <div className="product-image">
                  <img src={prod.imageUrl} alt={prod.name} />
                </div>
                <div className="product-info">
                  <h3>{prod.name}</h3>
                  <p className="price">{formatPrice(prod.price)}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    return (
        <React.Fragment>
          
            <ToastContainer />
            {pageLoading && (
            <div className="page-loading-overlay">
                <div className="loader-container">
                    <div className="loader"></div>
                    <p>Loading product details...</p>
                </div>
            </div>
        )}
        <Helmet>
                <title>{product.name} - Buy Online | The Arena NG</title>
                <meta name="description" content={product.description} />
                <meta name="keywords" content={product.tags?.join(`, handmade, vintage, artworks, unique, handcrafted, weavon, arts, paints, bags, shoe, hat, sculptor, adire, ${product.category.value}, buy online in Nigeria, buy online in Africa,`)} />
                <meta name="author" content="The Arena NG" />
                <link rel="canonical" href={`https://thearenang.com/product/${product.id}/prod=${product.name}`} />
                
                {/* Open Graph (for Facebook, LinkedIn) */}
                <meta property="og:title" content={product.name} />
                <meta property="og:description" content={product.description} />
                <meta property="og:image" content={product.imageUrl} />
                <meta property="og:url" content={`https://thearenang.com/product/${product.id}/prod=${product.slug}`} />
                <meta property="og:type" content="product" />

                {/* Twitter Card */}
                <meta name="twitter:title" content={product.name} />
                <meta name="twitter:description" content={product.description} />
                <meta name="twitter:image" content={product.imageUrl} />
                <meta name="twitter:card" content="summary_large_image" />

                <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": product.name,
            "image": product.imageUrl,
            "description": product.description,
            "brand": {
                "@type": "Brand",
                "name": "Arena"
            },
            "offers": {
                "@type": "Offer",
                "priceCurrency": "NGN",
                "price": product.price,
                "availability": "https://schema.org/InStock",
                "url": `https://thearenang.com/product/${product.id}/prod=${product.name}`
            }
        })}
    </script>
            </Helmet>

            {showSlideshow && (
                <div className="slideshow-overlay" style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0,0,0,0.9)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1000
                }}>
                    <button onClick={() => setShowSlideshow(false)} style={{
                        position: 'absolute',
                        top: '20px',
                        right: '20px',
                        background: 'none',
                        border: 'none',
                        color: 'white',
                        fontSize: '24px',
                        cursor: 'pointer'
                    }}>×</button>
                    <button onClick={prevSlide} style={{
                        position: 'absolute',
                        left: '20px',
                        background: 'none',
                        border: 'none',
                        color: 'white',
                        fontSize: '24px',
                        cursor: 'pointer'
                    }}>←</button>
                    <img src={allImages[currentSlideIndex]} style={{
                        maxHeight: '90vh',
                        maxWidth: '90vw',
                        objectFit: 'contain'
                    }} />
                    <button onClick={nextSlide} style={{
                        position: 'absolute',
                        right: '20px',
                        background: 'none',
                        border: 'none',
                        color: 'white',
                        fontSize: '24px',
                        cursor: 'pointer'
                    }}>→</button>
                </div>
            )}
            <div className='shop-band'>
                <section className='sb-left'>
                    <p style={{margin:0}}> Home &gt; Shop &gt; | {product ? product.name : ""}</p>
                </section>
            </div>
            {/* <div className='product-details'>
                <section className='image-section'>
                    <div className='images-list'>
                        <ul>
                                <li onClick={() => handleImageClick(product? product.imageUrl : "")}>
                                    <img className='prod-image' src={product ? product.imageUrl : ""} />
                                </li>
                            {product?.image2 && (
                                <li onClick={() => handleImageClick(product.image2)}>
                                    <img className='prod-image' src={product.image2} />
                                </li>
                            )}
                            {product?.image3 && (
                                <li onClick={() => handleImageClick(product.image3)}>
                                    <img className='prod-image' src={product.image3} />
                                </li>
                            )}
                            {product?.image4 && (
                                <li onClick={() => handleImageClick(product.image4)}>
                                    <img className='prod-image' src={product.image4} />
                                </li>
                            )}
                            {product?.image5 && (
                                <li onClick={() => handleImageClick(product.image5)}>
                                    <img className='prod-image' src={product.image5} />
                                </li>
                            )}
                        </ul>
                    </div>
                    <div style={{display: "flex", flexDirection:"column", alignItems:"center", padding: 0, margin: 0}}>
                    <img 
                        src={mainImage} 
                        className='main-prod-image' 
                        onClick={() => {
                            setCurrentSlideIndex(allImages.indexOf(mainImage));
                            setShowSlideshow(true);
                        }}
                        style={{ cursor: 'pointer' }}
                    />

                    <div style={{display:"flex", justifyContent:"space-between", gap:"20px"}}>
                        <section className="quan-section">
                            <Button onClick={decreaseQuantity} className="button-primary">-</Button>
                            &nbsp;
                            {quantity}
                            &nbsp;
                            <Button onClick={increaseQuantity} className="button-primary">+</Button>
                        </section>
                        <Button onClick={addToCart} className={`button-primary add-to-cart-btn ${loading ? 'loading' : ''}`}
                    disabled={loading}
                >
                    {loading ? (
                        <span className="loader"></span>
                    ) : (
                        'Add to Cart'
                    )}</Button>
                    </div>
                    {error && (
                    <div className="error-message">
                        {error}
                    </div>
                )}
                    </div>
                </section>
                <section className='desc-section'>
                    <div>
                        <h3 className='prod-name'>{product ? product.name : ""}</h3>
                        <p className='prod-price'>{product ? formatPrice(product.price):"0"} {product? (product.priceAfterDiscount && product.priceAfterDiscount > 0) ?<span><s>{ formatPrice(product.priceAfterDiscount)}</s></span>: "":""}</p>
                        <p className='prod-desc'>{product && product.description}</p>
                        <p className='prod-sizes' style={{marginBottom:"0px"}}>Sizes :</p>
                        <div className="sizes-container" style={{marginBottom:"20px"}}>
                            {product && product.size.split(',').map((size, index) => (
                                <span
                                    key={index}
                                    className={`size-box ${o_size.includes(size.trim()) ? 'selected' : ''}`}
                                    onClick={() => setSize(size.trim())}
                                    style={{
                                        border: '1px solid #ccc',
                                        padding: '5px 10px',
                                        margin: '0 5px',
                                        cursor: 'pointer',
                                        display: 'inline-block',
                                        backgroundColor: o_size.includes(size.trim()) ? '#f0f0f0' : 'white',
                                        position: 'relative'
                                    }}
                                >
                                    {o_size.includes(size.trim()) && (
                                        <span style={{
                                            position: 'absolute',
                                            top: '-8px',
                                            right: '-8px',
                                            fontSize: '12px',
                                            color: 'green'
                                        }}>✓</span>
                                    )}
                                    {size.trim()}
                                </span>
                            ))}
                        </div>
                        <p className='prod-colors' style={{marginBottom:"0px"}}>Colors :</p>
                        <div className="colors-container" style={{marginBottom:"20px"}}>
                            {product? product.color.split(',').map((color, index) => (
                                <span
                                    key={index}
                                    className={`color-box ${o_color.includes(color.trim()) ? 'selected' : ''}`}
                                    onClick={() => setColor(color.trim())}
                                    style={{
                                        border: '1px solid #ccc',
                                        padding: '5px 10px',
                                        margin: '0 5px',
                                        cursor: 'pointer',
                                        display: 'inline-block',
                                        backgroundColor: color.trim().toLowerCase(),
                                        color: ['white', 'yellow', 'beige'].includes(color.trim().toLowerCase()) ? 'black' : 'white',
                                        position: 'relative'
                                    }}
                                >
                                    {o_color.includes(color.trim()) && (
                                        <span style={{
                                            position: 'absolute',
                                            top: '-8px',
                                            right: '-8px',
                                            fontSize: '12px',
                                            color: 'white',
                                            textShadow: '0px 0px 2px black'
                                        }}>✓</span>
                                    )}
                                    {color.trim()}
                                </span>
                            )): ""}
                        </div>
                    </div>
                    <div style={{display:"flex", justifyContent:"space-between", gap:"20px"}}>
                        <section className="quan-section">
                            <Button onClick={decreaseQuantity} className="button-primary">-</Button>
                            &nbsp;
                            {quantity}
                            &nbsp;
                            <Button onClick={increaseQuantity} className="button-primary">+</Button>
                        </section>
                        <Button onClick={addToCart} className={`button-primary add-to-cart-btn ${loading ? 'loading' : ''}`}
                    disabled={loading}
                >
                    {loading ? (
                        <span className="loader"></span>
                    ) : (
                        'Add to Cart'
                    )}</Button>
                    </div>
                    {error && (
                    <div className="error-message">
                        {error}
                    </div>
                )}
                    <hr/>
                    <div>
                        <p>Category : {product?.category.value || 'Uncategorized'}</p>
                        <p>Tags : {product?.tags || 'No tags'}</p>
                    <div className="social-share">
                        <Button 
                            className="button-primary"
                            onClick={() => {
                                if (navigator.share) {
                                    navigator.share({
                                        title: product.name,
                                        text: product.description,
                                        url: window.location.href,
                                    })
                                    .catch((error) => console.log('Error sharing:', error));
                                } else {
                                    navigator.clipboard.writeText(window.location.href)
                                    .then(() => toast.success('Link copied to clipboard!'))
                                    .catch(() => toast.error('Failed to copy link'));
                                }
                            }}
                        >
                            Share
                        </Button>
                    </div>
                    </div>
                    <div></div>
                </section>
            </div> */}

<div className="product-details">
        {renderProductImages()}

        <section className="desc-section">
          <h1 className="prod-name">{product?.name}</h1>
          <p className="prod-price">
            {formatPrice(product?.price || 0)}
            {product?.priceAfterDiscount > 0 && (
              <s>{formatPrice(product.priceAfterDiscount)}</s>
            )}
          </p>

          <p className="prod-desc">{product?.description}</p>

          {renderVariants()}
          {renderQuantityControls()}

          <button 
            className={`add-to-cart-btn ${loading ? 'loading' : ''}`}
            onClick={addToCart}
            disabled={loading}
          >
            {loading ? <span className="loader" /> : 'Add to Cart'}
          </button>

          {error && <div className="error-message">{error}</div>}

          <div className="product-meta">
            <p>Category: {product?.category?.value || 'Uncategorized'}</p>
            <p>Tags: {product?.tags || 'No tags'}</p>
          </div>
        </section>
      </div>

      {/* Add vendor's other products */}
    {products.length > 0 && (
      renderRelatedProducts("More From This Vendor", products)
    )}

    {/* Add similar products */}
    {similarProducts.length > 0 && (
      renderRelatedProducts("Similar Products", similarProducts)
    )}
            {/* <Products className="illustration-section-01" products={products} /> */}
        </React.Fragment>
    );
}


export default Product;