import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './LoginForm.scss';
import { Link } from 'react-router-dom';
import Checkbox from '../../components/elements/Checkbox';
import ErrorToast from '../ErrorToast/ErrorToast';
import * as authActions from '../../actions/authActions/authActions';
import Spinner from '../Spinner/Spinner';
import logo from '../../assets/images/arena-logo-normal-svg.svg';
import GoogleSignIn from '../sections/GoogleBusinessSignin';
import Util from '../../utils';

export class LoginForm extends Component {
  loginUser = e => {
    e.preventDefault();
    const { loginUser, auth: { loginErrors }, clearLoginErrors } = this.props;

    if (loginErrors.length) clearLoginErrors();

    const email = this.email.value;
    const password = this.password.value;
    const deviceId = "";

    loginUser({ email, password });
  };

  handleSuccess = async (data) => {
    await this.props.googleLogin(data);
          }

  render() {
    const { auth: { isAuthenticated, userRole, isLoading, loginErrors } } = this.props;

    // Ensure the redirect happens only once after successful authentication 
        if (isAuthenticated) {
      return <Navigate to="/business/dashboard" />;
    }

    return (
      <section className="form__wrapper">
        <div className="form">
          <img className="form__logo" src={logo} alt="logo" />
          <h4 className="form__appname">Store Manager</h4>
          <p className="form__description">
            Effortlessly track your inventory and sales while staying organized and in control.
          </p>
          <p className="form__header">Login with your account.</p>
          <form id="login-form" className="form__login" onSubmit={this.loginUser}>
            {loginErrors.length ? <ErrorToast errors={loginErrors} /> : null}
            <div className="form__control">
              <input
                autoFocus
                type="email"
                id="login-email"
                className="form__input"
                placeholder="email@storemanager.com"
                required
                pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?<!\+.*)$"
                ref={email => (this.email = email)}
              />
            </div>
            <div className="form__control">
              <input
                type="password"
                id="login-password"
                className="form__input"
                placeholder="Password"
                required
                ref={password => (this.password = password)}
              />
            </div>

            <div className="form__control">
              <button type="submit" className="btn btn--gradient full-width" disabled={isLoading ? true : null}>
                {isLoading ? <Spinner /> : 'Login'}
              </button>
            </div>
            <div className="signin-footer mb-32">
              <Checkbox>Remember me</Checkbox>
              <Link to="/business/forgot-password" className="func-link text-xs">Forgot password?</Link>
            </div>
          </form>
          <div className="divider" style={{textAlign:"center"}}>
        <span>OR</span>
      </div>
      
      <GoogleSignIn onSuccess={this.handleSuccess} />
          <div className="signin-bottom has-top-divider">
            <div className="pt-32 text-xs center-content text-color-low">
              Don't you have an account? <Link to="/business/register/" className="func-link">Sign up</Link>
            </div>
          </div>
        </div>
        <div className="form__sidepane" />
      </section>
    );
  }
}

LoginForm.propTypes = {
  loginUser: PropTypes.func.isRequired,
  googleLogin: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    userRole: PropTypes.string,
    isLoading: PropTypes.bool,
    loginErrors: PropTypes.array
  }).isRequired,
  clearLoginErrors: PropTypes.func.isRequired
};

const mapStateToProp = state => ({
  auth: state.auth
});

const mapActionsToProp = { loginUser: authActions.loginUser, googleLogin: authActions.googleLogin , clearLoginErrors: authActions.clearLoginErrors };

export default connect(
  mapStateToProp,
  mapActionsToProp
)(LoginForm);
