import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingBag, faTruck, faUndo, faHeadset } from '@fortawesome/free-solid-svg-icons';
import HeroSplit from '../components/sections/HeroSplit';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesTabs from '../components/sections/FeaturesTabs';
import News from '../components/sections/News';
import Roadmap from '../components/sections/Roadmap';
import Pricing from '../components/sections/Pricing';
import Cta from '../components/sections/Cta';
import HeroSlider from '../components/sections/HeroSlider';
import '../App.css';
import ShopperForm from '../components/sections/ShopperForm';
import img1 from '../assets/images/african_bg.jpg';
import next from '../assets/images/next_arrow.svg';
import img2 from '../assets/images/splash-screen-2.png';
import classNames from 'classnames';
import Image from '../components/elements/Image';
import About from '../components/sections/about';
import GenericSection from '../components/sections/GenericSection';
import SectionHeader from '../components/sections/partials/SectionHeader';
import Button from '../components/elements/Button';
import Modal from '../components/elements/Modal';
import Input from '../components/elements/Input';
import '../assets/scss/core/elements/_gallery.scss';
import Products from '../components/sections/Products';
import { getAllProducts, getCategories } from '../network/product_api';
import art from '../assets/images/art.jpg'
import art2 from '../assets/images/art2.jpg'
import art3 from '../assets/images/art3.jpg'
import art4 from '../assets/images/art4.jpg'
import CurrencySelector from '../components/CurrencySelector/CurrencySelector';
import { formatPrice } from '../network/currency_api';
import playstore from '../assets/images/playstore.svg';
import appstore from '../assets/images/appstore.svg';
import HeroCarousel from '../components/sections/HeroCarousel';

const Home = () => {
  const [trendingProducts, setTrendingProducts] = useState([]);
  const [featuredCategories, setFeaturedCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    try {
      const [productsRes, categoriesRes] = await Promise.all([
        getAllProducts(),
        getCategories().then(res =>  res["message"])
      ]);
      console.log(productsRes)
      
      setTrendingProducts(productsRes.content.slice(0, 30));
      setFeaturedCategories(categoriesRes);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Arena - Handmade & Vintage Marketplace in Nigeria</title>
        <meta name="description" content="Discover unique handmade products, vintage items, and local artisan creations. Shop authentic African art, jewelry, clothing, and more." />
        <meta name="keywords" content="handmade, vintage, African art, Nigerian marketplace, artisan products, jewelry, clothing, pottery, woodworks, leatherworks" />
        <meta property="og:title" content="Arena - Handmade & Vintage Marketplace" />
        <meta property="og:description" content="Discover unique handmade products and vintage items from Nigerian artisans" />
        <meta property="og:image" content="/path-to-your-og-image.jpg" />
      </Helmet>

      <main className="home-container">
        {/* Hero Section */}
        <section className="hero-section">
          <div className="hero-content">
            <motion.h1 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="hero-title"
            >
              Discover Unique Handmade Treasures
            </motion.h1>
            <p className="hero-subtitle">
              Shop authentic African artisan products, from traditional art to modern designs
            </p>
            <div className="hero-cta">
              <Button 
                className="button-primary"
                onClick={() => window.location.href = '/shop'}
              >
                Shop Now
              </Button>
              <Button 
                className="button-secondary"
                onClick={() => window.open("https://play.google.com/store/apps/details?id=com.thearenang.arena")}
              >
                Get the App
              </Button>
            </div>
          </div>
          <div className="hero-carousel">
            {/* Your existing carousel code */}
            <HeroCarousel />
          </div>
        </section>

        {/* Trending Products */}
        <section className="trending-products">
          <h2>Trending Now</h2>
          {/* <Products className="illustration-section-01" products={trendingProducts} /> */}
          <div className="products-grid">
            {trendingProducts.map(product =>{
              const discount = (product.dicountpercent && product.dicountpercent > 0) ? 
              product.price * (product.dicountpercent / 100)
              : 0;
              console.log('discount:', discount)
              return (
              <motion.div 
                key={product.id}
                whileHover={{ y: -10 }}
                className="product-card"
              >
                <img src={product.imageUrl} alt={product.name} />
                { product.dicountpercent != null || product.dicountpercent > 0 ?
        <div className='discount-label' >{product.dicountpercent}% OFF</div>
        :
        // <div className='new-label' >New</div>
        <></>
        }
                <p className='item-name'>
                                                                    {product.name && (product.name.length > 20 
                                                                        ? product.name.substring(0, 20) + '...' 
                                                                        : product.name)}
                                                                </p>
                                                                <p className='item-price'>
                                                                    {product.dicountpercent != null && product.dicountpercent > 0 ? (
                                                                        <>
                                                                            <span>{formatPrice(product.price - discount)}</span>
                                                                            <span className='item-oldPrice'>
                                                                                <s>{formatPrice(product.price)}</s>
                                                                            </span>
                                                                        </>
                                                                    ) : (
                                                                        <span>{formatPrice(product.price)}</span>
                                                                    )}
                                                                </p>
                {/* <p>{product.name}</p>
                <p className="price">{formatPrice(product.price)}</p> */}
                <Button 
                  className="button-secondary"
                  onClick={() => window.location.href = `/product/${product.id}/?prod=${product.name}`}
                >
                  View Details
                </Button>
              </motion.div>
            );
            }
            )
}
          </div>
        </section>

        {/* Value Propositions */}
        <section className="value-props">
          <div className="value-grid">
            {[
              { icon: faTruck, title: 'Fast Delivery', text: 'Global shipping' },
              { icon: faUndo, title: 'Easy Returns', text: '30-day return policy' },
              { icon: faHeadset, title: '24/7 Support', text: 'Always here to help' },
              { icon: faShoppingBag, title: 'Secure Shopping', text: 'Safe & trusted payments' }
            ].map((prop, index) => (
              <div key={index} className="value-item">
                <FontAwesomeIcon icon={prop.icon} />
                <h3>{prop.title}</h3>
                <p>{prop.text}</p>
              </div>
            ))}
          </div>
        </section>
        <header className='banner'>
          <section className='banner-inner'>
            <div className='txt'>
              <p className='banner-inner-tag'>New Arrival</p>
              <h3 className='title'>Exclusive Collections</h3>
              <p className='desc'>Discover amazing handmade products and vintage items for your personal collections</p>
              <Button className='banner-inner-btn button-primary' onClick={() => window.location.href = '/shop'}>Shop Now</Button>
            </div>
          </section>
        </header>

        {/* Featured Categories */}
        <section className="featured-categories">
          <h2>Shop by Category</h2>
          <div className="categories-grid">
            {featuredCategories.map(category => (
              <motion.div 
                key={category.id}
                whileHover={{ scale: 1.05 }}
                className="category-card"
              >
                {/* <img src={category.image} alt={category.value} /> */}
                <h3>{category.value}</h3>
                {/* <p>{category.productCount} Products</p> */}
              </motion.div>
            ))}
          </div>
        </section>

        <News className="illustration-section-01" />
 

      </main>
    </>
  );
};

export default Home;