import React, { useState, useEffect } from 'react';
import PageHeader from '../components/sections/PageHeader';
import Button from '../components/elements/Button';
import { faDeleteLeft, faDumpster, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useNavigate } from 'react-router-dom';
import { deleteOrder, getAllOrders, updateOrder } from '../network/order_api';
import { formatPrice } from '../network/currency_api';

const Cart = () => {
    const [cart, setCart] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [expandedVendors, setExpandedVendors] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        fetchCartItems();
    }, []);

    const fetchCartItems = async () => {
        try {
            setLoading(true);
            const response = await getAllOrders('pending');
            // Group orders by business
            const groupedOrders = response.data.reduce((acc, order) => {
                const businessName = order.business.businessName;
                if (!acc[businessName]) {
                    acc[businessName] = {
                        vendor: businessName,
                        items: [],
                        address: order.business.address,
                        phone: order.business.phoneNumber.toString(),
                        email: order.business.email
                    };
                }
                // // Add products to the business group
                // order.products.forEach(product => {
                    const product = order.products;
                    if (product) {
                        acc[businessName].items.push({
                            id: product.id,
                            product: product.name,
                            order_id: order.id,
                            quantity: order.quantity,
                            category: product.category.value,
                            description: order.description,
                            price: product.price
                        });
                    }
                // });
                return acc;
            }, {});

            // Convert the grouped object to array format
            setCart(Object.values(groupedOrders));
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch cart items');
            setLoading(false);
        }
    };

    if (loading) return <div>Loading cart...</div>;
    if (error) return <div>{error}</div>;


    const toggleVendor = (vendor) => {
        setExpandedVendors(prevState => ({
            ...prevState,
            [vendor]: !prevState[vendor]
        }));
    };

    const increaseQuantity = (vendorIndex, itemIndex) => {
        const newCart = [...cart];
        newCart[vendorIndex].items[itemIndex].quantity += 1;
        setCart(newCart);
        updateOrder(cart[vendorIndex].items[itemIndex].order_id, { quan: newCart[vendorIndex].items[itemIndex].quantity });
    };

    const decreaseQuantity = (vendorIndex, itemIndex) => {
        const newCart = [...cart];
        if (newCart[vendorIndex].items[itemIndex].quantity > 1) {
            newCart[vendorIndex].items[itemIndex].quantity -= 1;
            setCart(newCart);
            updateOrder(cart[vendorIndex].items[itemIndex].order_id, { quan: newCart[vendorIndex].items[itemIndex].quantity });
        }
    };

    const removeItem = (vendorIndex, itemIndex) => {
        const newCart = [...cart];
        try {
            // Remove item from the database
            deleteOrder(cart[vendorIndex].items[itemIndex].order_id).then((response) => {
                
                
                if (response.status < 300) {
                    const modal = window.confirm('Item removed from cart!');
                    newCart[vendorIndex].items.splice(itemIndex, 1);
                    setCart(newCart);
                    // if (modal) {
                    //     window.location.href = '/cart';
                    // } else {
                    //     window.location.href = '/shop';
                    // }
                }
            }
            );
        } catch (err) {
            alert('Failed to remove item');
        }
    };

    const checkoutVendor = (vendorIndex) => {
        const vendor = [cart[vendorIndex]];

        navigate('/checkout', { state: { vendor } });
    };

    const checkoutAll = () => {
        const vendor = cart;
        navigate('/checkout', { state: { vendor } });
    };

    const renderVendorItems = (vendor, vendorIndex) => (
        <div className="vendor-items">
            <table>
                <thead>
                    <tr>
                        <th>Product</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Subtotal</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {vendor.items.map((item, itemIndex) => (
                        <tr key={item.id}>
                            <td className="product-info">
                                <div>{item.product}</div>
                                <small>{item.description}</small>
                            </td>
                            <td>
                                <div className="quantity-controls">
                                    <button 
                                        onClick={() => decreaseQuantity(vendorIndex, itemIndex)}
                                        disabled={item.quantity <= 1}
                                    >
                                        -
                                    </button>
                                    <span className="quantity">{item.quantity}</span>
                                    <button 
                                        onClick={() => increaseQuantity(vendorIndex, itemIndex)}
                                    >
                                        +
                                    </button>
                                </div>
                            </td>
                            <td>{formatPrice(item.price)}</td>
                            <td>{formatPrice(item.quantity * item.price)}</td>
                            <td>
                                <button 
                                    className="remove-button"
                                    onClick={() => removeItem(vendorIndex, itemIndex)}
                                >
                                    <FontAwesomeIcon icon={faRemove} />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );


    // return (
    //     <React.Fragment>
    //         <PageHeader page="Cart" />
    //         <div className='cart_container'>
    //             <section className='cart_left'>
    //                 <table>
    //                     <thead>
    //                         <tr>
    //                             <th>Business</th>
    //                             <th></th>
    //                             <th></th>
    //                             <th></th>
    //                         </tr>
    //                     </thead>
    //                     <tbody>
    //             {cart.map((vendor, vendorIndex) => (
    //                 <tr key={vendor.vendor} className="cart_item">
    //                     <td>{vendor.vendor}</td> 
    //                     <td>{vendor.items.length} item(s)</td>
    //                     <td>{formatPrice(vendor.items.reduce((acc, item) => acc + item.quantity * item.price, 0))}</td>
    //                     <td>
    //                     <Button className='button-primary' onClick={() => toggleVendor(vendor.vendor)}>
    //                         {expandedVendors[vendor.vendor] ? 'Hide Items' : 'Show Items'}
    //                     </Button>&nbsp;
    //                     {expandedVendors[vendor.vendor] && (
    //                         <table>
    //                             <thead>
    //                                 <tr>
    //                                     <th>Product</th>
    //                                     <th>Quantity</th>
    //                                     <th>Price</th>
    //                                     <th>Subtotal</th>
    //                                     <th></th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 {vendor.items.map((item, itemIndex) => (
    //                                     <tr key={item.id}>
    //                                         <td>{item.product}</td>
    //                                         <td>
    //                                             <button className='button-primary' onClick={() => decreaseQuantity(vendorIndex, itemIndex)} style={{    minWidth: "30px"}}>-</button>
    //                                             <span className='cart-quan'>{item.quantity}</span>
    //                                             <button className='button-primary' onClick={() => increaseQuantity(vendorIndex, itemIndex)} style={{    minWidth: "30px"}}>+</button>
    //                                         </td>
    //                                         <td>{formatPrice( item.price)}</td>
    //                                         <td>{formatPrice(item.quantity * item.price)}</td>
    //                                         <td>
    //                                             <button className='button-primary' onClick={() => removeItem(vendorIndex, itemIndex)} style={{    minWidth: "30px"}}><FontAwesomeIcon icon={faRemove}></FontAwesomeIcon></button>
    //                                         </td>
    //                                     </tr>
    //                                 ))}
    //                             </tbody>
    //                         </table>
    //                     )}
    //                     &nbsp;<Button className='button-primary' onClick={() => checkoutVendor(vendorIndex)}>Checkout</Button>
    //                     </td>
    //                 </tr>
    //             ))}
    //             </tbody>
    //                 </table>

    //             </section>
    //             <section className='cart_right'>
    //                 <h2>Cart Totals</h2>
    //                 <span>
    //                 <p>Subtotal: </p>
    //                 <p>&nbsp;</p>
    //                 </span>
    //                 <span>
    //                 <p>Cart Total: </p>
    //                 <p className='total'>
    //                 {formatPrice(cart.reduce((total, vendor) => 
    //                     total + vendor.items.reduce((acc, item) => acc + item.quantity * item.price, 0), 0))}
    //                     </p>
    //                 </span>
    //                 {/* <Button className='button-primary' onClick={() => checkoutAll()}>Proceed to Checkout All</Button> */}
    //             </section>
    //         </div>
    //     </React.Fragment>
    // );

    return (
        <React.Fragment>
            <PageHeader page="Shopping Cart" />
            
            {loading ? (
                <div className="loading-overlay">
                    <div className="loader"></div>
                    <p>Loading your cart...</p>
                </div>
            ) : error ? (
                <div className="error-message">{error}</div>
            ) : cart.length === 0 ? (
                <div className="empty-cart">
                    <h2>Your cart is empty</h2>
                    <p>Browse our products and add items to your cart</p>
                    <Button 
                        className="button-primary"
                        onClick={() => navigate('/shop')}
                    >
                        Continue Shopping
                    </Button>
                </div>
            ) : (
                <div className="cart-container">
                    <section className="cart-left">
                        {cart.map((vendor, vendorIndex) => (
                            <div key={vendor.vendor} className="vendor-section">
                                <div className="vendor-header">
                                    <div className="vendor-info">
                                        <h3>{vendor.vendor}</h3>
                                        <span>{vendor.items.length} item(s)</span>
                                    </div>
                                    <div className="vendor-actions">
                                        <Button 
                                            className="toggle-button"
                                            onClick={() => toggleVendor(vendor.vendor)}
                                        >
                                            {expandedVendors[vendor.vendor] ? 'Hide Items' : 'Show Items'}
                                        </Button>
                                        <Button 
                                            className="checkout-button"
                                            onClick={() => checkoutVendor(vendorIndex)}
                                        >
                                            Checkout
                                        </Button>
                                    </div>
                                </div>
                                {expandedVendors[vendor.vendor] && renderVendorItems(vendor, vendorIndex)}
                            </div>
                        ))}
                    </section>

                    <section className="cart-right">
                        <h2>Order Summary</h2>
                        <div className="summary-item">
                            <span>Subtotal</span>
                            <span>{formatPrice(cart.reduce((total, vendor) => 
                                total + vendor.items.reduce((acc, item) => 
                                    acc + item.quantity * item.price, 0), 0))}
                            </span>
                        </div>
                        <div className="summary-item total">
                            <span>Total</span>
                            <span>{formatPrice(cart.reduce((total, vendor) => 
                                total + vendor.items.reduce((acc, item) => 
                                    acc + item.quantity * item.price, 0), 0))}
                            </span>
                        </div>
                        <Button 
                            className="checkout-button button-primary"
                            onClick={checkoutAll}
                            disabled={cart.length === 0}
                        >
                            Checkout All Items
                        </Button>
                    </section>
                </div>
            )}
        </React.Fragment>
    );
}

export default Cart;