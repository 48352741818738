import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as authActions from '../../actions/authActions/authActions';
import './ForgotPassword.scss';
import { Navigate } from 'react-router-dom';
import ErrorToast from '../ErrorToast/ErrorToast';
import Spinner from '../Spinner/Spinner';
import logo from '../../assets/images/arena-logo-normal-svg.svg';
import GoogleSignIn from '../sections/GoogleBusinessSignin';
import Util from '../../utils';
import { Link } from 'react-router-dom';

class BizGetStartedForm extends React.Component {
  state = {
    showRegistration: false,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    referralCode: ''
  };

  componentDidUpdate(prevProps) {
    if (prevProps.auth.loginErrors !== this.props.auth.loginErrors) {
      const { loginErrors } = this.props.auth;
      if (loginErrors.some(error => error.endsWith('does not exist'))) {
        this.setState({ showRegistration: true });
      }
    }
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleGetStarted = async (e) => {
    e.preventDefault();
    const { sendOtp,
      register,
      auth: { loginErrors },
     clearLoginErrors
   } = this.props;
   if (loginErrors.length) clearLoginErrors();

    const { email } = e.target.elements;
    
    if (this.state.showRegistration) {
      const { firstName, lastName, phoneNumber, referralCode } = this.state;
      await this.props.register({
        email: email.value,
        firstName,
        lastName,
        phoneNumber,
        role:"ROLE_BUSINESS",
        referralCode
      });
      await sendOtp({email : email.value, deviceId: ""});
    } else {
      await sendOtp({email : email.value, deviceId: ""});
    }
  };

  handleSuccess = async (data) => {
    await this.props.googleLogin(data);
          }

  render() {
    const { showRegistration } = this.state;
    const { auth: { otpSent, isLoading, loginErrors } } = this.props;

    if (otpSent) {
      return <Navigate to="/business/verify-otp" />;
    }
    const token = localStorage.getItem('user');
     if (token) {
          return <Navigate to="/business/dashboard" />;
        }

    return (
      <section className="form__wrapper">
        <div className="form">
          <img className="form__logo" src={logo} alt="logo" />
          <h4 className="form__appname">Store Manager</h4>
          <p className="form__header">Get Started</p>
          <p className="form__description">
            {showRegistration 
              ? "Complete your registration to become a merchant"
              : "Enter personal account email address to becoming a merchant."}
          </p>
          <form className="form__login" onSubmit={this.handleGetStarted}>
            {loginErrors.length && !showRegistration ? <ErrorToast errors={loginErrors} /> : null}
            
            <div className="form__control">
              <input
                autoFocus
                type="email"
                id="login-email"
                name="email"
                placeholder="Email Address"
                required
              />
            </div>

            {showRegistration && (
              <>
                <div className="form__control">
                  <input
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={this.state.firstName}
                    onChange={this.handleInputChange}
                    required
                  />
                </div>

                <div className="form__control">
                  <input
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={this.state.lastName}
                    onChange={this.handleInputChange}
                    required
                  />
                </div>

                <div className="form__control">
                  <input
                    type="tel"
                    name="phoneNumber"
                    placeholder="Phone Number"
                    value={this.state.phoneNumber}
                    onChange={this.handleInputChange}
                    required
                  />
                </div>

                <div className="form__control">
                  <input
                    type="text"
                    name="referralCode"
                    placeholder="Referral Code (Optional)"
                    value={this.state.referralCode}
                    onChange={this.handleInputChange}
                  />
                </div>
              </>
            )}

            <button 
              className={`form__button ${isLoading ? 'loading' : ''}`}
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? 'Processing...' : 'Continue'}
            </button>
          </form>
          <div className="divider" style={{textAlign:"center"}}>
        <span>OR</span>
      </div>
      
      <GoogleSignIn onSuccess={this.handleSuccess} />
          <div className="signin-bottom has-top-divider">
            <div className="pt-32 text-xs center-content text-color-low">
              Don't you have an account? <Link to="/business/register/" className="func-link">Sign up</Link>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

BizGetStartedForm.propTypes = {
  sendOtp: PropTypes.func.isRequired,
  register : PropTypes.func.isRequired,
  googleLogin : PropTypes.func.isRequired,
  auth: PropTypes.shape({
    otpSent: PropTypes.bool,
    user: PropTypes.string,
    isLoading: PropTypes.bool,
    loginErrors: PropTypes.array
  }).isRequired,
  clearLoginErrors: PropTypes.func.isRequired
};

const mapStateToProp = state => ({
  auth: state.auth
});

const mapActionsToProps = { sendOtp: authActions.sendOtp, register: authActions.register, googleLogin: authActions.googleLogin, clearLoginErrors: authActions.clearLoginErrors };

export default connect(mapStateToProp, mapActionsToProps)(BizGetStartedForm);