import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as salesActions from '../../actions/salesActions/salesActions';
import Util from '../../utils';
import Loading from '../Loading/Loading';
import ToastContainer from '../Toasts/ToastContainer';
import Pagination from '../Pagination/Pagination';
import Modal from 'react-modal';

class SalesRecordPane extends Component {
  componentDidMount() {
    this.props.populateSales();
  }

  constructor(props) {
    super(props);
    this.state = {
    showModal: false,
    selectedOrder: null
  };
  }



  render() {
    const {
      sales,
      sales: { salesMade, isLoading },
    } = this.props;
    const {
      showModal,
      selectedOrder
    } = this.state;
    if (isLoading) {
      return <Loading title="Fetching Sales Records" />;
    }
    return (
      <section className="main">
        <section className="sales">
          <ToastContainer message="" />
          {/* <div className="sales__meta">
            <div className="sort">
              <section className="filters">
                <form id="sort-date" className="filters__form">
                  <fieldset>
                    <legend>Date Range</legend>
                    <strong>From: </strong>
                    <input type="date" id="from-date" required />
                    <strong>To: </strong>
                    <input type="date" id="to-date" required />
                    <input type="submit" value="Retrieve Records" />
                  </fieldset>
                </form>
                <form id="sort-id" className="filters__form">
                  <fieldset>
                    <legend>By Sales ID</legend>
                    <input type="text" pattern="[0-9]*" id="sale-id" placeholder="Enter Sales ID" />
                    <input type="submit" value="Retrieve Records" />
                  </fieldset>
                </form>
                <form id="sort-user" className="filters__form">
                  <fieldset>
                    <legend>By Attendant</legend>
                    <select id="users-list">
                      <option value="0">Select User</option>
                    </select>
                    <input type="submit" value="Retrieve Records" />
                  </fieldset>
                </form>
                <button type="button" className="filters__clear" id="clear-filters-admin">
                  Clear filters
                </button>
              </section>
            </div>
          </div> */}
          <div className="table-wrapper">
            <table id="my-sales-table" className="table">
              <thead>
                <tr>
                  <th>S/N</th>
                  <th colSpan="1">Date</th>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Total</th>
                  {/* <th>Customer</th> */}
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {salesMade.map((sale, index) => {
                    
                    const handleViewOrder = (sale) => {
                    this.setState({ selectedOrder: sale });
                    this.setState({ showModal: true });
                    };

                    return (
                    <>
                      <tr key={sale.id * index}>
                      <td>{index + 1}</td>
                      <td>{Util.formatDate(sale.timestamp)}</td>
                      <td>{sale.products && sale.products.name ? sale.products.name : ''}</td>
                      <td>{sale.quantity}</td>
                      <td>{Util.formatPrice(sale.products && sale.products.price ? sale.products.price : 0)}</td>
                      <td>{Util.formatCurrency(sale.amount)}</td>
                      <td>{sale.status}</td>
                      <td>
                      <button onClick={() => handleViewOrder(sale)} className="blue">View</button>
                      {!sale.ready && sale.status !== 'CANCELLED' && sale.status !== 'COMPLETED' && (
                        <>
                        <button onClick={() => handleReadyUpdate(sale.id,sale.user.id)} className="blue">Ready</button>
                        <button onClick={() => handleStatusUpdate(sale.id, 'Cancelled')} className="red">Can't fulil</button>
                        </>
                      )}
                      </td>
                      </tr>
                      {showModal && selectedOrder && (
                      <Modal
                      isOpen={showModal}
                      onRequestClose={() => this.setState({showModal: false})}
                      shouldCloseOnEsc
                      ariaHideApp={false}
                      contentLabel="View Modal"
                      overlayClassName="modal-overlay" className={`modal ${showModal ? 'is-active' : ''}`}>
                      <div className="modal-content" style={{zIndex: 1,     color: 'wheat'}}>
                      <h2>Order Details</h2>
                      <p>Order ID: {selectedOrder.id}</p>
                      <p>Product: {selectedOrder.products?.name}</p>
                      <p>Quantity: {selectedOrder.quantity}</p>
                      <p>Total: {Util.formatCurrency(selectedOrder.amount)}</p>
                      <p>Status: {selectedOrder.status}</p>
                      <button onClick={() => this.setState({showModal: false})}>Close</button>
                      </div>
                      </Modal>
                      )}
                    </>
                    );
                })}
              </tbody>
            </table>
          </div>
          {salesMade.length ? <Pagination entity={sales} allProps={this.props} /> : null}
        </section>
      </section>
    );
  }
}

function handleStatusUpdate(orderId, status, ref) {
  const customer = JSON.parse(localStorage.getItem('userInfo'));
  // const body = { 
  //   "orderId":orderId,
  //     "userId": customer.id,
  //     "status": status.toUpperCase()
  //  };
  const body = new FormData();
  body.append('orderId', orderId);
  body.append('userId', customer.id);
  body.append('status', status.toUpperCase());
  if (ref) {
    body.append('ref', ref);
  }

  Util.makeRequest(`/orders/${orderId}/change-status`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: body,
  }).then(() => {
    window.location.reload();
    // setOrders(orders.map((order) => (order.id === orderId ? { ...order, status } : order)));
  });
}

function handleReadyUpdate(orderId, user) {
  // const customer = localStorage.getItem('userInfo');
  const body = { 
    "orderId":orderId,
      "userId": user,
      "ready": true
   };
  Util.makeRequest(`/orders/${orderId}/update`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: body,
  }).then(() => {
    window.location.reload();
    // setOrders(orders.map((order) => (order.id === orderId ? { ...order, status } : order)));
  });
}


const mapStateToProp = state => ({
  sales: state.sales

});

const mapActionsToProp = {
  populateSales: salesActions.populateSales,
  goToNextPage: salesActions.goToNextPage,
  goToPrevPage: salesActions.goToPrevPage
};

export default connect(
  mapStateToProp,
  mapActionsToProp
)(SalesRecordPane);
