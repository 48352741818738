import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';
import './GoogleSignIn.scss';
import { googleLogin } from '../../network/api';

const GoogleSignIn = ({ onSuccess }) => {
  const handleSuccess = async (credentialResponse) => {
    try {
      const response = await googleLogin({"token":credentialResponse.credential})

      const data = response.message;

      if (response.httpStatusCode > 300) {
        throw new Error(data || 'Failed to sign in with Google');
      }

      // Store auth token
      localStorage.setItem('token', data.token);
      localStorage.setItem('user', JSON.stringify(data.user));

      // Call success callback
      onSuccess(data);
      
      toast.success('Successfully signed in with Google!');
    } catch (error) {
      toast.error(error.message);
      console.error('Google sign in error:', error);
    }
  };

  const handleError = () => {
    toast.error('Google sign in failed. Please try again.');
  };

  return (
    <div className="google-sign-in">
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        onSuccess={handleSuccess}
        onError={handleError}
        useOneTap
        theme="filled_blue"
        size="large"
        text="signin_with"
        shape="rectangular"
      />
    </div>
  );
};

export default GoogleSignIn;