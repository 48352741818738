import React, { useEffect, useState } from "react";
import { useLocation, Navigate } from 'react-router-dom';
import PageHeader from "../components/sections/PageHeader";
import { GoogleMap, LoadScript, Autocomplete } from '@react-google-maps/api';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import Button from "../components/elements/Button";
import { deliveryAPI } from "../network/delivery_api";
import { _ } from "ajv";
import { addNewLogistic } from "../network/logistics_api";
import { changeOrderStatus, confirmOrder, updateOrder } from "../network/order_api";
import { toast, ToastContainer } from "react-toastify";
import { Country, State, City } from 'country-state-city';
import { formatPrice } from "../network/currency_api";
import { getHSCodeMapping } from "../utils/hscodeMapper";


const Uuid = require('uuid');

const CheckoutPage = () => {

    const location = useLocation();
    var { vendor } = location.state != null ? location.state : {return: <Navigate to="/shop" replace />};
    const user = JSON.parse(localStorage.getItem('user'));

    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [selectedRider, setSelectedRider] = useState(null);
    const [deliveryFee, setDeliveryFee] = useState(0);
    const [deliveryDuration, setDeliveryDuration] = useState('');
    const [logisticsCompanies, setLogisticsCompanies] = useState([]);
    const [redisKey, setRedisKey] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [couponCode, setCouponCode] = useState('');
    const [isApplyingCoupon, setIsApplyingCoupon] = useState(false);
    const [couponError, setCouponError] = useState('');
    const [couponDiscount, setCouponDiscount] = useState(0);
    const [apartment, setApartment] = useState('');
    const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber ? user.phoneNumber.toString() : '');
    const [deliveryInstructions, setDeliveryInstructions] = useState('');
    const [serviceFee, setServiceFee] = useState(0);
    const [total, setTotal] = useState(0);

    const [isInternational, setIsInternational] = useState(false);
const [hsCodeCategory, setHsCodeCategory] = useState(null);

// Add category mapping function
const mapProductCategoryToHSCode = (productCategory) => {
  const categoryMap = {
    'Clothing': 'Articles of Apparel and Clothing Accessories, Not Knitted or Crocheted',
    'Shoes': 'Footwear, Gaiters, and the Like; Parts of Such Articles',
    'Books': 'Printed Books, Newspapers, Pictures and Other Products of the Printing Industry',
    // Add more mappings as needed
  };
  return categoryMap[productCategory] || 'Other Made-Up Textile Articles; Sets';
};

// Add this before the address form
const renderDeliveryTypeSelection = () => (
  <div className="delivery-type-selection">
    <h2>Delivery Location</h2>
    <div className="delivery-options">
      <button 
        className={`delivery-option ${!isInternational ? 'selected' : ''}`}
        onClick={() => setIsInternational(false)}
      >
        Within Nigeria
      </button>
      <button 
        className={`delivery-option ${isInternational ? 'selected' : ''}`}
        onClick={() => setIsInternational(true)}
      >
        International Shipping
      </button>
    </div>
  </div>
);

    var subtotal = 0;
    // const subtotal = vendor.map((acc, vendor) => {
        //     return acc + vendor.items.reduce((vendorAcc, item) => vendorAcc + item.quantity * item.price, 0);
        // }, 0);
    // const subtotal = 0;
    vendor != null ? 
    vendor.forEach (vendor => {
        subtotal += vendor.items.reduce((acc, item) => acc + item.quantity * item.price, 0);
    }) :
    window.location.href = '/cart';
    
    useEffect(() => {
        if (!user) {
            localStorage.setItem('redirectAfterLogin', window.location.pathname);
                window.location.href = '/login';
                return;
        }
        if (vendor == null) {
            alert("There is no item in checkout process. Kindly proceed to your cart");
        }
        
        const sub = subtotal * 0.1;
        setServiceFee (sub);
        setTotal(subtotal + sub + deliveryFee - couponDiscount);
    }, [deliveryFee,couponDiscount]);

    var items_list = [];


   
    // const logisticsCompanies = [
    //     { name: 'Logistics 1', price: 10, duration: '30 mins' },
    //     { name: 'Logistics 2', price: 15, duration: '45 mins' },
    //     { name: 'Logistics 3', price: 20, duration: '1 hour' }
    // ];

    const handlePlaceSelect = (place) => {
        setAddress(place.formatted_address);
    };

    const handleRiderSelect = (rider) => {
        setDeliveryFee(0)
        setCouponDiscount(0)
        setSelectedRider(rider.courier.id);
        setDeliveryFee(rider.amount);
        setDeliveryDuration(rider.duration);
    };

    const handleApplyCoupon = async () => {
        setIsApplyingCoupon(true);
        try {
            // Simulate applying coupon
            setTimeout(() => {
                if (couponCode === 'FREEDELIVERY') {
                    setCouponDiscount(deliveryFee);
                } else {
                    setCouponError('Invalid coupon code');
                }
                setIsApplyingCoupon(false);
            }, 2000);
        } catch (err) {
            setIsApplyingCoupon(false);
        }
    };

    // setPhoneNumber(user.phoneNumber);

    const config = user ? {
        // public_key: 'FLWPUBK_TEST-e571f1dc4b9768d37c81409892b4f23e-X',
        public_key: 'FLWPUBK-960d0ee588adca2f493ee8d119444eee-X',
        tx_ref: Date.now(),
        amount: total,
        currency: 'NGN',
        payment_options: 'card,mobilemoney,ussd',
        customer: {
          email: user.email,
          phone_number: user.phoneNumber,
          name: user.firstName + ' ' + user.lastName,
        },
        customizations: {
          title: 'The ArenaNG',
          description: 'Payment for items in cart',
          logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
        },
    } : {};
    
    const ids = vendor.map(v => v.items.map(item => item.order_id).join('.')).join('.');
          const ref1 = Math.random().toString(36).substring(2, 20);
          const tx_ref = `${Uuid.v1()}.${ids}.REF${ref1}`;
          config.tx_ref = tx_ref;
      const handleFlutterPayment = useFlutterwave(config);

      const fetchDeliveryOptions = async () => {
        setIsLoading(true);
        try {
            const items_list = [];
            // vendor.forEach(biz => 
            //     biz.items.map(async element => {
                for (const biz of vendor) {
                    for (const element of biz.items) {
                        const hsCodeData = await getHSCodeMapping(element.category);
                    console.log(hsCodeData);
                    items_list.push({
                        name: element.product,
                        category: element.category,
                        description: element.description,
                        weight: element.weight != null ? element.weight : 1,
                        amount: element.price < 500 ? 500 : element.price,
                        quantity: element.quantity,
                        ...(isInternational && { 
                            hscode: 
                                {category: hsCodeData.category,
                                    subcategory : hsCodeData.subcategory,
                                     code: hsCodeData.hscode}
                            
            //             })
            //         });
            //     })
            // );
        })
    });
}
}
    
            const params = {
                // "type": !country ? "interstate" : 
                //     (vendor[0].country === country ? 
                //     (vendor[0].state === state ? "intrastate" : "interstate") 
                //     : "international"),
                "type": isInternational ? "international" : 
        (vendor[0].state === state ? "intrastate" : "interstate"),
                "toAddress": {
                    "name": user.firstName + ' ' + user.lastName,
                    "email": user.email,
                    "address": address + ', ' + apartment + ', ' + city + ', ' + state + ', ' + country,
                    "phone": phoneNumber.startsWith('+234') ? '0' + phoneNumber.slice(4) : phoneNumber,
                },
                "fromAddress": {
                    "name": vendor[0].vendor,
                    "email": vendor[0].email, 
                    "address": vendor[0].address + ', ' + vendor[0].city + ', ' + vendor[0].state + ', ' + vendor[0].country,
                    "phone": vendor[0].phone.startsWith('+234') ? '0' + vendor[0].phone.slice(4) : vendor[0].phone,
                },
                "parcels": {
                    "width": 32.5,
                    "length": 32.5,
                    "height": 32.5,
                    "weight": 2
                },
                "items": items_list
            };
    
            const response = await deliveryAPI.getAllRates(params);
            const rates = response.data.data.rates;
            const filteredRates = rates.filter(element => element["status"] !== false);
            setRedisKey(response.data.data.redis_key);
            setLogisticsCompanies(filteredRates);
        } catch (error) {
            toast.error('Error fetching delivery rates');
            console.error('Error:', error);
        } finally {
            setIsLoading(false);
        }
    };
    
      const handldeOrder = () => {
          vendor[0].items.map((v) => {
            console.log(v)
        updateOrder(v.order_id, {deliveryType: selectedRider ? "delivery":"pickup"} )
        }
    );
        if (selectedRider) {
            addNewLogistic(vendor[0].items[0].order_id,vendor[0].address + ', ' + vendor[0].city + ', ' + vendor[0].state + ', ' + vendor[0].country, address + ', ' + apartment + ', ' + city + ', ' + state + ', ' + country, phoneNumber, deliveryInstructions, selectedRider, redisKey).then(() => {
            }).catch(err => {
            }
        )
    }  
    handleFlutterPayment({
        callback: (response) => {
            console.log(response)
            closePaymentModal(); // this will close the modal programmatically
            if (response.status === "successful" || response.status === "completed") {
                // alert("Payment Success")
               
                Promise.all(vendor[0].items.map((v) => {
                    console.log(v);
                    return changeOrderStatus(v.order_id, "CONFIRMED", ref1);
                }))
                .then(() => {
                    toast.success("Payment successful! Order confirmed.");
                    window.location.href = '/orders'; // Redirect to orders page
                })
                .catch((_err) => {
                    toast.success("Payment successful but order status update failed.");
                });
            } else {
                toast.error("Payment failed. Please try again.");
            }
            setIsLoading(false);
        },
        onClose: () => {
            // alert("Payment was cancelled.");
            toast.error("Payment was cancelled.");
            setIsLoading(false);
        },
    });

    }  



    // return (
    //     <React.Fragment>
    //         <PageHeader page="Checkout" />
    //         <ToastContainer />
    //         <div className="checkout-container">
    //             <div className="checkout-left">
    //                 <h2>Delivery Address</h2>
    //                 <div className="address-form"></div>
    //                     <LoadScript googleMapsApiKey="AIzaSyA0eTqZ5UtsrQjT9AOO23pLNYnOt9yOujk" libraries={['places']}>
    //                         <Autocomplete
    //                             onPlaceSelected={handlePlaceSelect}
    //                             types={['address']}
    //                             componentRestrictions={{ country: 'NG' }}
    //                         >
    //                             <input
    //                                 type="text"
    //                                 placeholder="Enter your street address"
    //                                 value={address}
    //                                 onChange={(e) => setAddress(e.target.value)}
    //                                 required
    //                             />
    //                         </Autocomplete>
    //                     </LoadScript>
    //                     <input
    //                         type="text"
    //                         placeholder="Apartment/Suite/Unit (optional)"
    //                         onChange={(e) => setApartment(e.target.value)}
    //                     />
    //                     <div className="address-details">
    //                     <select
    //                             required
    //                             value={country}
    //                             onChange={(e) => {
    //                                 setCountry(e.target.value);
    //                                 setState('');
    //                                 setCity('');
    //                             }}
    //                         >
    //                             <option value="">Select Country</option>
    //                             {Country.getAllCountries().map((country) => (
    //                                 <option key={country.isoCode} value={country.isoCode}>
    //                                     {country.name}
    //                                 </option>
    //                             ))}
    //                         </select>
    //                         <select
    //                             required
    //                             value={state}
    //                             onChange={(e) => {
    //                                 setState(e.target.value);
    //                                 setCity('');
    //                             }}
    //                             disabled={!country}
    //                         >
    //                             <option value="">Select State</option>
    //                             {State.getStatesOfCountry(country).map((state) => (
    //                                 <option key={state.isoCode} value={state.isoCode}>
    //                                     {state.name}
    //                                 </option>
    //                             ))}
    //                         </select>
    //                         <select
    //                             required
    //                             value={city}
    //                             onChange={(e) => setCity(e.target.value)}
    //                             disabled={!state}
    //                         >
    //                             <option value="">Select City</option>
    //                             {City.getCitiesOfState(country, state).map((city) => (
    //                                 <option key={city.name} value={city.name}>
    //                                     {city.name}
    //                                 </option>
    //                             ))}
    //                         </select>
    //                         {/* <input
    //                             type="text"
    //                             placeholder="City"
    //                             required
    //                             onChange={(e) => setCity(e.target.value)}
    //                         />
    //                         <select 
    //                             required
    //                             onChange={(e) => setState(e.target.value)}
    //                         >
    //                             <option value="">Select State</option>
    //                             <option value="Lagos">Lagos</option>
    //                             <option value="Abuja">Abuja</option>
    //                             <option value="Rivers">Rivers</option>
                              
    //                         </select>
    //                         <select
    //                             required 
    //                             onChange={(e) => setCountry(e.target.value)}
    //                         >
    //                             <option value="Nigeria">Nigeria</option>
    //                         </select> */}
    //                     </div>
    //                     <input
    //                         type="text"
    //                         placeholder="Phone Number"
    //                         required
    //                         onChange={(e) => setPhoneNumber(e.target.value)}
    //                     />
    //                     <input
    //                         type="text"
    //                         placeholder="Delivery Instructions (optional)"
    //                         onChange={(e) => setDeliveryInstructions(e.target.value)}
    //                     />
    //                     <Button 
    //                         className="button-primary" 
    //                         disabled={isLoading || !address || !phoneNumber}
    //                         onClick={async () => {
    //                                 setIsLoading(true);
    //                                 try {
    //                                     vendor.forEach(
    //                                         (biz) => biz.items.map((element) => {
    //                                             items_list.push({
    //                                             name: element.product,
    //                                             category: element.category,
    //                                             description: element.description,
    //                                             weight: element.weight != null ? element.weight : 1,
    //                                             amount: element.price < 500 ? 500 : element.price,
    //                                             quantity: element.quantity
    //                                         })
                                            
    //                                     }
    //                                     )
    //                                 );
    //                                     var params = {
    //                                         "type": !country ? "interstate" : 
    //                                             (vendor[0].country === country ? 
    //                                             (vendor[0].state === state ? "intrastate" : "interstate") 
    //                                             : "international"),
    //                                         "toAddress": {
    //                                           "name": user.firstName + ' ' + user.lastName,
    //                                           "email": user.email,
    //                                           "address": address + ', ' + apartment + ', ' + city + ', ' + state + ', ' + country,
    //                                           "phone": phoneNumber.startsWith('+234') ? '0' + phoneNumber.slice(4) : phoneNumber,
    //                                         },
    //                                         "fromAddress": {
    //                                           "name": vendor[0].vendor,
    //                                           "email": vendor[0].email, 
    //                                           "address": vendor[0].address + ', ' + vendor[0].city + ', ' + vendor[0].state + ', ' + vendor[0].country,
    //                                           "phone": vendor[0].phone.startsWith('+234') ? '0' + vendor[0].phone.slice(4) : vendor[0].phone,
    //                                         },
    //                                         "parcels": {
    //                                           "width": 32.5,
    //                                           "length": 32.5,
    //                                           "height": 32.5,
    //                                           "weight": 2
    //                                         },
    //                                         "items":items_list
    //                                       };
    //                                     deliveryAPI.getAllRates(params).then(response => {
                                            
    //                                         const rates = response.data.data.rates;
    //                                         const filteredRates = rates.filter((element) => element["status"] !== false);
    //                                         setRedisKey(response.data.data.redis_key);
    //                                         setLogisticsCompanies(filteredRates);
                                            
    //                                     }).catch(err => {
    //                                         alert('Error fetching delivery rates');
    //                                     })
    //                                     .finally(() => {
    //                                         setIsLoading(false);
    //                                     });
    //                                 } catch (err) {
    //                                     setIsLoading(false);
    //                                 }
    //                             }}
    //                         >
    //                             {isLoading ? 'Loading...' : 'Get Delivery Options'}
    //                         </Button>
    //                 {
    //                     logisticsCompanies.length > 0 && (
    //                         <React.Fragment>
    //                             <h2>Delivery Options</h2>
    //                             <ul>
    //                                 {logisticsCompanies.map((logisticsCompany, index) => (
    //                                     <li key={index} onClick={() => handleRiderSelect(logisticsCompany)}>
    //                                        <img src={logisticsCompany.courier.icon} style={{width:"30px",    display: "inline-flex"}}/>  {logisticsCompany.courier.name} - ₦{logisticsCompany.amount} - {logisticsCompany.estimated_days}
    //                                     </li>
    //                                 ))}
    //                             </ul>
    //                         </React.Fragment>
    //                     )
    //                 }
    //                 {/* <h2>Select Rider</h2>
    //                 <ul>
    //                     {logisticsCompanies.map((rider, index) => (
    //                         <li key={index} onClick={() => handleRiderSelect(rider)}>
    //                            <img src={rider.courier.icon} style={{width:"50px",    display: "inline-flex"}}/> {rider.courier.name} - ₦{rider.amount} - {rider.estimated_days}
    //                         </li>
    //                     ))}
    //                 </ul> */}
    //             </div>
    //             <div className="checkout-right">
    //                 <h2>Summary</h2>
    //                 <p>Items Total: {formatPrice(subtotal)}</p>
    //                 <p>Service Fee (10%): {formatPrice(serviceFee)}</p>
    //                 <p>Delivery Fee: {formatPrice(deliveryFee)}</p>
    //                 <p>Sub-Total: {formatPrice((subtotal + serviceFee + deliveryFee))}</p>
    //                 <div className="coupon-section">
    //                     <h2>Apply Coupon</h2>
    //                     <div className="coupon-input" style={{display: 'flex', gap: '10px'}}>
    //                         <input 
    //                             type="text" 
    //                             placeholder="Enter coupon code"
    //                             value={couponCode}
    //                             onChange={(e) => setCouponCode(e.target.value)}
    //                         />
    //                         <Button 
    //                             className="button-secondary"
    //                             onClick={handleApplyCoupon}
    //                             disabled={isApplyingCoupon}
    //                         >
    //                             {isApplyingCoupon ? 'Applying...' : 'Apply'}
    //                         </Button>
    //                     </div>
    //                     {couponError && <p className="error-message">{couponError}</p>}
    //                     {couponDiscount > 0 && (
    //                         <p className="discount-amount">Coupun Discount: {formatPrice(couponDiscount)}</p>
    //                     )}
    //                 </div>
    //                 &nbsp;
    //                 {/* <h2>Payment Options</h2>
    //                 <select>
    //                     <option value="credit-card">Credit Card</option>
    //                     <option value="paypal">PayPal</option>
    //                     <option value="bank-transfer">Bank Transfer</option>
    //                 </select> */}
    //                 <p>Total: {formatPrice(total)}</p>
    //                 <Button className="button-primary" 
    //     disabled={!selectedRider || isLoading}
    //     onClick={() => {
    //         if (!selectedRider) {
    //             alert('Please select a delivery option first');
    //             return;
    //         }
    //         setIsLoading(true);
    //         handldeOrder();
    //     }}>
    //     {isLoading ? 'Processing...' : 'Proceed to Payment'}
    // </Button>
    //             </div>
    //         </div>
    //     </React.Fragment>
    // );

    // Update the JSX structure
return (
    <React.Fragment>
        <PageHeader page="Checkout" />
        <ToastContainer />
        
        {isLoading && (
            <div className="loading-overlay">
                <div className="loader"></div>
                <p>Processing your request...</p>
            </div>
        )}

        <div className="checkout-container">
            <div className="checkout-left">
            {renderDeliveryTypeSelection()}
                <section className="address-section">
                    <h2>Delivery Address</h2>
                    <div className="address-form">
                         <LoadScript googleMapsApiKey="AIzaSyA0eTqZ5UtsrQjT9AOO23pLNYnOt9yOujk" libraries={['places']}>
                            <Autocomplete
                                onPlaceSelected={handlePlaceSelect}
                                types={['address']}
                                componentRestrictions={{ country: 'NG' }}
                            >
                                <input
                                    type="text"
                                    placeholder="Enter your street address"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    required
                                />
                            </Autocomplete>
                        </LoadScript>

                        <input
                            type="text"
                            placeholder="Apartment/Suite/Unit (optional)"
                            value={apartment}
                            onChange={(e) => setApartment(e.target.value)}
                        />

                        <div className="address-details">
                            <select
                                value={country}
                                onChange={(e) => {
                                    setCountry(e.target.value);
                                    setState('');
                                    setCity('');
                                }}
                                required
                            >
                                <option value="">Select Country</option>
                                {Country.getAllCountries().map((country) => (
                                    <option key={country.isoCode} value={country.isoCode}>
                                        {country.name}
                                    </option>
                                ))}
                            </select>

                            <select
                                value={state}
                                onChange={(e) => {
                                    setState(e.target.value);
                                    setCity('');
                                }}
                                disabled={!country}
                                required
                            >
                                <option value="">Select State</option>
                                {State.getStatesOfCountry(country).map((state) => (
                                    <option key={state.isoCode} value={state.isoCode}>
                                        {state.name}
                                    </option>
                                ))}
                            </select>

                            <select
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                disabled={!state}
                                required
                            >
                                <option value="">Select City</option>
                                {City.getCitiesOfState(country, state).map((city) => (
                                    <option key={city.name} value={city.name}>
                                        {city.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <input
                            type="tel"
                            placeholder="Phone Number"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            required
                        />

                        <textarea
                            placeholder="Delivery Instructions (optional)"
                            value={deliveryInstructions}
                            onChange={(e) => setDeliveryInstructions(e.target.value)}
                            rows={3}
                        />

                        <Button 
                            className="button-primary get-delivery-options" 
                            disabled={isLoading || !address || !phoneNumber}
                            onClick={fetchDeliveryOptions}
                        >
                            {isLoading ? 'Loading...' : 'Get Delivery Options'}
                        </Button>
                    </div>
                </section>

                {logisticsCompanies.length > 0 && (
                    <section className="delivery-options">
                        <h2>Delivery Options</h2>
                        <ul>
                            {logisticsCompanies.map((company, index) => (
                                <li 
                                    key={index}
                                    className={selectedRider === company.courier.id ? 'selected' : ''}
                                    onClick={() => handleRiderSelect(company)}
                                >
                                    <img src={company.courier.icon} alt={company.courier.name} />
                                    <div className="courier-info">
                                        <div className="courier-name">{company.courier.name}</div>
                                        <div className="courier-details">
                                            {formatPrice(company.amount)} • {company.estimated_days}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </section>
                )}
            </div>

            <div className="checkout-right">
                <section className="order-summary">
                    <h2>Order Summary</h2>
                    <div className="summary-item">
                        <span className="label">Items Total:</span>
                        <span className="value">{formatPrice(subtotal)}</span>
                    </div>
                    <div className="summary-item">
                        <span className="label">Service Fee (10%):</span>
                        <span className="value">{formatPrice(serviceFee)}</span>
                    </div>
                    <div className="summary-item">
                        <span className="label">Delivery Fee:</span>
                        <span className="value">{formatPrice(deliveryFee)}</span>
                    </div>

                    <div className="coupon-section">
                        <h3>Have a Coupon?</h3>
                        <div className="coupon-input">
                            <input 
                                type="text" 
                                placeholder="Enter coupon code"
                                value={couponCode}
                                onChange={(e) => setCouponCode(e.target.value)}
                            />
                            <Button 
                                className="button-secondary"
                                onClick={handleApplyCoupon}
                                disabled={isApplyingCoupon || !couponCode}
                            >
                                {isApplyingCoupon ? 'Applying...' : 'Apply'}
                            </Button>
                        </div>
                        {couponError && <p className="error-message">{couponError}</p>}
                        {couponDiscount > 0 && (
                            <div className="discount-amount">
                                Discount Applied: {formatPrice(couponDiscount)}
                            </div>
                        )}
                    </div>

                    <div className="total">
                        <div className="summary-item">
                            <span className="label">Total:</span>
                            <span className="value">{formatPrice(total)}</span>
                        </div>
                    </div>

                    <Button 
                        className="button-primary checkout-button"
                        disabled={!selectedRider || isLoading}
                        onClick={handldeOrder}
                    >
                        {isLoading ? 'Processing...' : 'Proceed to Payment'}
                    </Button>
                </section>
            </div>
        </div>
    </React.Fragment>
);
}

export default CheckoutPage;